import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {
  DateFilterNames,
  EFilterName,
  IDateRange,
  IFilterState,
  IOption,
  TFilterCombination
} from '@/shared/models/tasksFilterModel';
import {logout} from '@/stores/AuthStore';
import {apiWithDictionaries} from '@/stores/api/dictionaries';
import {initialState, updateFiltersFromDictApi} from '@/stores/TasksFilterStore/utils';

export const appealsFilterStateSlice = createSlice({
  name: 'tasksFilterState',
  initialState,
  reducers: {
    updateListOptionByKey: (
      state,
      action: PayloadAction<{key: EFilterName; options: IOption[]}>
    ) => {
      if (action.payload) {
        const {key, options} = action.payload;
        const initOptions = initialState.filterList[key].options;

        state.filterList[key] = {
          ...state.filterList[key],
          options: [...initOptions, ...options]
        };
      }
    },
    updateTempValueByKey: (
      state: IFilterState,
      action: PayloadAction<{key: EFilterName; value?: string}>
    ) => {
      if (action.payload) {
        const {key, value} = action.payload;
        if (key) {
          state.tempFilterValues[key] = value;
        }
      }
    },
    updateValueByKey: (
      state: IFilterState,
      action: PayloadAction<{key: TFilterCombination; value?: string}>
    ) => {
      if (action.payload) {
        const {key, value} = action.payload;

        if (DateFilterNames.includes(<EFilterName>key)) {
          const dates = value ? value.match(/([0-9]{4}-[0-9]{2}-[0-9]{2})/g) : undefined;
          if (dates) {
            state.filterListDates[key] = {
              start: dates[0],
              end: dates[1]
            };
          }
        }

        if (key) {
          state.filterValues[key] = value;
        }
      }
    },
    updateDatesValueByKey: (
      state,
      action: PayloadAction<{key: EFilterName; value?: IDateRange}>
    ) => {
      const {key, value} = action.payload;
      state.filterListDates[key] = value;
    },
    toggleUpdate: (state, action: PayloadAction<boolean>) => {
      state.isUpdated = action.payload;
    },
    resetFilterValues: state => {
      state.filterValues = initialState.filterValues;
    },
    resetState: state => {
      state.tempFilterValues = initialState.tempFilterValues;
      state.filterValues = initialState.filterValues;
      state.filterListDates = initialState.filterListDates;
      state.isUpdated = initialState.isUpdated;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(logout, () => {
        return initialState;
      })
      .addMatcher<any>(apiWithDictionaries.endpoints.getAllUnits.matchFulfilled, (...args) =>
        updateFiltersFromDictApi(...args)(EFilterName.Units)
      );
  }
});

export const {
  updateListOptionByKey,
  updateTempValueByKey,
  updateValueByKey,
  toggleUpdate,
  resetFilterValues,
  resetState,
  updateDatesValueByKey
} = appealsFilterStateSlice.actions;

export const tasksFilterStateReducer = appealsFilterStateSlice.reducer;
