import React, {useCallback, useState} from 'react';
import {Menu, MenuItem, Typography} from '@mui/material';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';

import {
  useReadAllChatMessagesMutation,
  useReadAllChatGroupMessagesMutation
} from '@/stores/api/chats';

interface ChatContextMenuProps {
  chatId?: string;
  chatGroupId?: string;
  children: React.ReactNode;
}

const ChatContextMenu: React.FC<ChatContextMenuProps> = ({chatId, chatGroupId, children}) => {
  const [menuPosition, setMenuPosition] = useState<{mouseX: number; mouseY: number} | null>(null);

  const [triggerReadChat] = useReadAllChatMessagesMutation();
  const [triggerReadGroup] = useReadAllChatGroupMessagesMutation();

  const handleContextMenu = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    setMenuPosition({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4
    });
  }, []);

  const handleClose = () => {
    setMenuPosition(null);
  };

  const handleMarkAsRead = () => {
    if (chatGroupId) {
      triggerReadGroup({chatGroupId});
    } else if (chatId) {
      triggerReadChat({chatId});
    }
    handleClose();
  };

  return (
    <div onContextMenu={handleContextMenu} style={{cursor: 'context-menu'}}>
      {children}
      <Menu
        open={menuPosition !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          menuPosition !== null ? {top: menuPosition.mouseY, left: menuPosition.mouseX} : undefined
        }
      >
        <MenuItem
          onClick={handleMarkAsRead}
          style={{display: 'flex', alignItems: 'center', gap: 8}}
        >
          <MarkChatReadIcon fontSize="medium" />
          <Typography variant="body1">Пометить как прочитанное</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ChatContextMenu;
