import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {generatePath, useNavigate} from 'react-router';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  List,
  Stack,
  Tooltip
} from '@mui/material';
import {ExpandLess} from '@mui/icons-material';

import {useAppSelector} from '@/stores/hooks';
import {
  IQueryArg,
  useGetChatGroupListQuery,
  useGetChatListQuery,
  useGetChatTopicListQuery
} from '@/stores/api/chats';
import {ChatListItem} from '@/scenes/ChatsPage/components/ChatListItem';
import useLinearLoading from '@/shared/hooks/useLinearLoading';
import {SearchField} from '@/components/SearchField';
import {searchSelector} from '@/stores/SearchStateStore/SearchStateSelector';
import {PAGE_SIZE} from '@/scenes/AllTasksPage/scenes/constants';
import {IChatChannel, IChatGroupChannel} from '@/shared/models/chatModel';
import {RoutePaths} from '@/shared/constants/route';
import {userInfoSelector} from '@/stores/AuthStore/AuthStateSelector';
import {ChatList} from '@/shared/styles/chatStyles';
import {ChatContextMenu} from '@/scenes/ChatsPage/components/ChatContextMenu';

interface IProps {
  chatGroupId?: string;
  chatId?: string;
  chatTopicId?: string;
  isFetchingMessages?: boolean;
}

const ChatListSection: FC<IProps> = ({chatGroupId, chatId, chatTopicId, isFetchingMessages}) => {
  const navigate = useNavigate();
  const searchState = useAppSelector(searchSelector);
  const currentUser = useAppSelector(userInfoSelector);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGE_SIZE
  });

  const [chatGroupList, setChatGroupList] = useState<IChatGroupChannel[]>([]);
  const [chatList, setChatList] = useState<IChatChannel[]>([]);
  const [chatTopicList, setChatTopicList] = useState<IChatChannel[]>([]);

  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [selectedChatGroupId, setSelectedChatGroupId] = useState<string | undefined>(undefined);
  const [selectedChatId, setSelectedChatId] = useState<string | undefined>(undefined);
  const [selectedChatTelegramId, setSelectedChatTelegramId] = useState<number | undefined>(
    undefined
  );

  const [isOpenSubList, setIsOpenSubList] = useState(false);
  const [isOpenTopicList, setIsOpenTopicList] = useState(false);

  const [isMyUnit, setIsMyUnit] = useState(false);

  useEffect(() => {
    setPaginationModel({page: 0, pageSize: PAGE_SIZE});
  }, [searchState]);

  const chatGroupsQuery = useMemo(() => {
    const page = {
      limit: paginationModel.pageSize,
      offset: paginationModel.page
    };

    const filters: {my_chat_groups: string; find_titles_and_messages?: string; unit_id?: string} = {
      my_chat_groups: String(currentUser.id)
    };

    if (searchState) {
      filters.find_titles_and_messages = searchState;
    }

    if (isMyUnit && currentUser?.userUnits?.length) {
      const uniqueUnitIds = Array.from(
        new Set(
          currentUser.userUnits.map(unit => unit.unitId).filter(unitId => unitId !== undefined)
        )
      );
      filters.unit_id = 'in|' + uniqueUnitIds.join(',');
    }

    const counters = {
      user_unread_chat_messages: currentUser.id
    };

    const params: IQueryArg = {
      page,
      filters,
      counters
    };

    return params;
  }, [currentUser, paginationModel.pageSize, paginationModel.page, searchState, isMyUnit]);

  const {
    data: chatGroupData,
    isLoading: chatGroupIsLoading,
    isFetching: chatGroupIsFetching
  } = useGetChatGroupListQuery(chatGroupsQuery, {
    skip: !chatGroupsQuery || !currentUser || isFetchingMessages,
    refetchOnMountOrArgChange: true
  });

  const chatsQuery = useMemo(() => {
    const page = {
      limit: 0,
      offset: 0
    };

    // @ts-ignore
    const filters: {chat_group_id: string; is_notify: number; main_chats: number} = {
      is_notify: 0,
      main_chats: 1
    };

    if (selectedChatGroupId) {
      filters.chat_group_id = selectedChatGroupId;
    }

    const counters = {
      user_unread_chat_messages: currentUser.id
    };

    const params: IQueryArg = {
      page,
      filters,
      counters
    };

    return params;
  }, [currentUser, selectedChatGroupId]);

  const {
    data: chatData,
    isLoading: chatIsLoading,
    isFetching: chatIsFetching
  } = useGetChatListQuery(chatsQuery, {
    skip:
      !currentUser ||
      !selectedChatGroupId ||
      !chatsQuery ||
      (chatGroupIsFetching && !chatGroupIsLoading) ||
      isFetchingMessages,
    refetchOnMountOrArgChange: true
  });

  const chatTopicsQuery = useMemo(() => {
    const page = {
      limit: 0,
      offset: 0
    };

    // @ts-ignore
    const filters: {chat_group_id: string; is_notify: number; telegram_id: number} = {
      is_notify: 0
    };

    if (selectedChatTelegramId) {
      filters.telegram_id = selectedChatTelegramId;
    }

    if (selectedChatGroupId) {
      filters.chat_group_id = selectedChatGroupId;
    }

    const counters = {
      user_unread_chat_messages: currentUser.id
    };

    const params: IQueryArg = {
      page,
      filters,
      counters
    };

    return params;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, selectedChatTelegramId]);

  const {
    data: chatTopicData,
    isLoading: chatTopicIsLoading,
    isFetching: chatTopicIsFetching
  } = useGetChatTopicListQuery(chatTopicsQuery, {
    skip:
      !chatTopicsQuery ||
      !selectedChatTelegramId ||
      (chatGroupIsFetching && !chatGroupIsLoading) ||
      (chatIsFetching && !chatIsLoading) ||
      isFetchingMessages,
    refetchOnMountOrArgChange: true
  });

  const listRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    if (
      listRef.current &&
      !chatGroupIsFetching &&
      !chatGroupIsLoading &&
      hasMore &&
      !isLoadingMore
    ) {
      const {scrollTop, scrollHeight, clientHeight} = listRef.current;
      if (scrollHeight - scrollTop <= clientHeight + 0.5) {
        setIsLoadingMore(true);
        setPaginationModel(prev => ({
          ...prev,
          pageSize: prev.pageSize + PAGE_SIZE
        }));
      }
    }
  }, [listRef, hasMore, chatGroupIsFetching, chatGroupIsLoading, isLoadingMore]);

  const handleClickListItem = (
    newChatGroupId: string,
    newChatId?: string,
    newChatTopicId?: string,
    isSubListClick = false,
    isTopicListClick = false,
    hasChatTopics = false
  ) => {
    if (!isSubListClick && !isTopicListClick) {
      if (selectedChatGroupId === newChatGroupId) {
        setIsOpenSubList(!isOpenSubList);
      } else {
        setChatList([]);
        setIsOpenSubList(false);
      }
    }

    if (isSubListClick && !isTopicListClick) {
      if (newChatId && (selectedChatId !== newChatId || !isOpenTopicList) && hasChatTopics) {
        setIsOpenTopicList(!isOpenTopicList);
      } else {
        setChatTopicList([]);
        setIsOpenTopicList(false);
      }
    }

    if (isTopicListClick || (!chatTopicId && chatTopicData?.data.length === 1)) {
      if (!newChatTopicId) {
        newChatTopicId = String(chatTopicData?.data[0].id);
      }

      const path = generatePath(RoutePaths.ChatTopicPage, {
        chatGroupId: `${newChatGroupId}`,
        chatId: `${newChatId}`,
        chatTopicId: `${newChatTopicId}`
      });
      navigate(path);
    } else if (isSubListClick && !hasChatTopics) {
      const path = generatePath(RoutePaths.ChatPage, {
        chatGroupId: `${newChatGroupId}`,
        chatId: `${newChatId}`
      });
      navigate(path);
    } else if (!selectedChatGroupId) {
      const path = generatePath(RoutePaths.ChatGroupPage, {
        chatGroupId: `${newChatGroupId}`
      });
      navigate(path);
    }

    setSelectedChatGroupId(newChatGroupId);
    setSelectedChatId(newChatId);
  };

  useEffect(() => {
    const element = listRef.current;
    if (element) {
      element.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    if (chatGroupData?.data) {
      setChatGroupList(chatGroupData.data);
      setHasMore(chatGroupData.data.length >= paginationModel.pageSize);
      setIsLoadingMore(false);
    }
  }, [chatGroupData, paginationModel.pageSize, chatGroupId]);

  useEffect(() => {
    if (chatData?.data) {
      setChatList(chatData.data);
      setIsOpenSubList(!chatIsLoading && !chatIsFetching);

      const chatItem = chatData.data.find(chat => String(chat.id) === selectedChatId);
      if (chatItem && chatItem.telegramId) {
        setSelectedChatTelegramId(chatItem.telegramId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatData]);

  useEffect(() => {
    if (chatTopicData?.data) {
      setChatTopicList(chatTopicData.data);

      if (chatTopicData.data.length === 1) {
        const path = generatePath(RoutePaths.ChatTopicPage, {
          chatGroupId: `${selectedChatGroupId}`,
          chatId: `${selectedChatId}`,
          chatTopicId: `${chatTopicData.data[0].id}`
        });
        navigate(path);
      } else if (!isOpenTopicList) {
        setIsOpenTopicList(!chatTopicIsLoading && !chatTopicIsFetching);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatTopicData]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTo({top: 0, behavior: 'smooth'});
    }
  }, [searchState]);

  useEffect(() => {
    if (chatGroupId) {
      setSelectedChatGroupId(chatGroupId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatGroupId]);

  useEffect(() => {
    if (chatId) {
      setSelectedChatId(chatId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  useLinearLoading(
    chatGroupIsLoading ||
      chatGroupIsFetching ||
      chatIsLoading ||
      chatIsFetching ||
      chatTopicIsLoading ||
      chatTopicIsFetching ||
      isLoadingMore
  );

  return (
    <ChatList ref={listRef}>
      {(currentUser?.isHead || currentUser?.isHeadUnit) && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            width: '100%',
            paddingTop: '1%',
            marginLeft: '3%'
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={isMyUnit}
                onChange={e => setIsMyUnit(e.target.checked)}
                color={'secondary'}
              />
            }
            label="Моё подразделение"
          />
        </Box>
      )}
      <SearchField
        isStartAdornment={false}
        customRootStyles={{
          padding: '3%',
          position: 'sticky',
          top: -1,
          zIndex: 1
        }}
      />
      {chatGroupList.map(chatGroup => (
        <React.Fragment key={chatGroup.id}>
          <ChatContextMenu chatGroupId={String(chatGroup.id)}>
            <ChatListItem
              title={chatGroup.title}
              content={chatGroup.latestChatMessage?.content}
              createdAt={chatGroup.latestChatMessage?.createdAt}
              user={chatGroup.latestChatMessage?.user || chatGroup.latestChatMessage?.customer}
              unreadCount={chatGroup.userUnreadChatMessagesCount}
              isHasFiles={chatGroup.latestChatMessage?.isHasFiles}
              isSelected={Number(chatGroupId) === chatGroup.id && !!chatId}
              onClick={() => handleClickListItem(String(chatGroup.id))}
            />
          </ChatContextMenu>
          <Collapse
            key={chatGroup.id}
            in={isOpenSubList && !chatIsLoading && Number(selectedChatGroupId) === chatGroup.id}
            timeout="auto"
            unmountOnExit
          >
            {chatList.map(chat => (
              <React.Fragment key={chat.id}>
                <List component="div" disablePadding sx={{pl: 8}}>
                  <ChatContextMenu chatId={String(chat.id)}>
                    <ChatListItem
                      title={chat.customTitle || chat.title}
                      content={chat.latestTopicChatMessage?.content}
                      createdAt={chat.latestTopicChatMessage?.createdAt}
                      isTelegram={chat.isTelegram}
                      user={
                        chat.latestTopicChatMessage?.user || chat.latestTopicChatMessage?.customer
                      }
                      unreadCount={chat.userUnreadChatMessagesCount}
                      isHasFiles={chat.latestTopicChatMessage?.isHasFiles}
                      isSelected={Number(chatId) === chat.id}
                      isSubItem={true}
                      onClick={() => {
                        handleClickListItem(
                          String(chatGroup.id),
                          String(chat.id),
                          undefined,
                          true,
                          false,
                          Boolean(chat.telegramTopicId)
                        );
                        setSelectedChatTelegramId(chat.telegramId);
                      }}
                    />
                  </ChatContextMenu>
                </List>
                <Collapse
                  key={chat.id}
                  in={isOpenTopicList && !chatTopicIsLoading && Number(selectedChatId) === chat.id}
                  timeout="auto"
                  unmountOnExit
                >
                  {chatTopicList.map(chatTopic => (
                    <React.Fragment key={chatTopic.id}>
                      <List component="div" disablePadding sx={{pl: 16}}>
                        <ChatListItem
                          title={chatTopic.title}
                          content={chatTopic.latestChatMessage?.content}
                          createdAt={chatTopic.latestChatMessage?.createdAt}
                          isTelegram={chatTopic.isTelegram}
                          user={
                            chatTopic.latestChatMessage?.user ||
                            chatTopic.latestChatMessage?.customer
                          }
                          unreadCount={chatTopic.userUnreadChatMessagesCount}
                          isHasFiles={chatTopic.latestChatMessage?.isHasFiles}
                          isSelected={Number(chatTopicId) === chatTopic.id}
                          isSubItem={true}
                          onClick={() =>
                            handleClickListItem(
                              String(chatGroup.id),
                              String(chat.id),
                              String(chatTopic.id),
                              false,
                              true
                            )
                          }
                        />
                      </List>
                    </React.Fragment>
                  ))}
                </Collapse>
                {selectedChatId === String(chat.id) && isOpenTopicList && (
                  <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{pl: 16}}
                  >
                    <Tooltip title={'Свернуть темы чата'}>
                      <Button
                        variant="text"
                        color="inherit"
                        fullWidth
                        onClick={() => setIsOpenTopicList(!isOpenTopicList)}
                      >
                        {<ExpandLess />}
                      </Button>
                    </Tooltip>
                  </Stack>
                )}
              </React.Fragment>
            ))}
          </Collapse>
          {selectedChatGroupId === String(chatGroup.id) && isOpenSubList && (
            <Stack direction="column" alignItems="center" justifyContent="center">
              <Tooltip title={'Свернуть группу чатов'}>
                <Button
                  variant="text"
                  color="inherit"
                  fullWidth
                  onClick={() => setIsOpenSubList(!isOpenSubList)}
                >
                  {<ExpandLess />}
                </Button>
              </Tooltip>
            </Stack>
          )}
        </React.Fragment>
      ))}
    </ChatList>
  );
};

export default ChatListSection;
