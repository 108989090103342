import React, {FC, useCallback, useEffect, useState} from 'react';
import {generatePath, useNavigate, useParams} from 'react-router';
import {Link as RouterLink} from 'react-router-dom';
import {Breadcrumbs, Link, Skeleton, Stack, Typography} from '@mui/material';

import {useUpdateSubtaskMutation} from '@/stores/api/subtask-page/subtask-page';
import {useAppDispatch, useAppSelector} from '@/stores/hooks';
import {TaskSettingsSection} from '@/components/TaskSettingsSection';
import {ISubtaskUpdateRequest} from '@/shared/models/subtaskModel';
import {setTypeError, taskTypeErrorSelector} from '@/stores/TaskSettingsStore';
import {RoutePaths} from '@/shared/constants/route';
import DeadlineChip from '@/components/DeadlineChip/DeadlineChip';
import {ETaskStatuses} from '@/shared/constants/taskStatuses';
import {userInfoSelector} from '@/stores/AuthStore/AuthStateSelector';
import {checkTaskTypeMsg} from '@/shared/utils/taskUtils';
import {useSubtaskStreaming} from '@/shared/hooks/useSubtaskStreaming';

import s from '../TaskPage/TaskPage.module.css';

import {SubtaskChatWrapper} from './components/SubtaskChatWrapper';
import {DownloadAllButton} from './components/DownloadAllButton';
import {DisplaySystemCommentsButton} from './components/DisplaySystemCommentsButton';
import {SubtaskStatusButton} from './components/SubtaskStatusButton';
import {SubtaskHeaderControls} from './components/SubtaskHeaderControls';
import {useSubtaskData} from './useSubtaskData';

interface IProps {}

const SubtaskPage: FC<IProps> = () => {
  const {taskId} = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(userInfoSelector);
  const {data: subtask, isLoading, isError, isSuccess} = useSubtaskData({taskId});

  const [submitUpdateSubtask, {isLoading: isUpdateLoading}] = useUpdateSubtaskMutation();

  const [isDisplaySystemComments, setIsDisplaySystemComments] = useState<boolean>(false);

  const errorType = useAppSelector(taskTypeErrorSelector);

  const status = subtask?.taskStatusId;
  const taskType = subtask?.taskTypeId;
  const isAuthor = currentUser?.id === subtask?.authorId;

  useSubtaskStreaming({taskId});

  const handleChangeStatus = (newStatus: number) => {
    const params: ISubtaskUpdateRequest = {
      task_status_id: newStatus
    };

    // если нет исполнителя, подставим себя
    if (!subtask?.userId && currentUser?.id) {
      params['user_id'] = currentUser.id;
    }

    // updated 22.08.23
    // перед тем как Взять в работу или Завершить,
    // нужно проставить тип
    const errorTypeMsg = checkTaskTypeMsg(taskType);
    if (errorTypeMsg) {
      dispatch(setTypeError(errorTypeMsg));
      return;
    }

    handleUpdateTask(params);
  };

  const handleUpdateTask = useCallback(
    (editedParams: ISubtaskUpdateRequest) => {
      if (!taskId) {
        console.warn('subtaskId не найден');
        return;
      }
      const params = {
        taskId,
        ...editedParams
      };

      // при смене исполнителя в рамках одного отдела
      // задача переходит из статуса "Принято в работу" на "Новая"
      if (params['user_id'] && !params['task_status_id']) {
        params['task_status_id'] = 1;
      }

      submitUpdateSubtask(params);

      // При завершении задачи нужно возвращать пользователя в главную таблицу.
      if (params['task_status_id'] === ETaskStatuses.Closed) {
        navigate(RoutePaths.MainSubtasks);
      }
    },
    [navigate, submitUpdateSubtask, taskId]
  );

  const handleDisplaySystemCommentsButton = () => {
    setIsDisplaySystemComments(!isDisplaySystemComments);
  };

  useEffect(() => {
    dispatch(setTypeError(null));
  }, [dispatch, taskType]);

  return (
    <div className="scene">
      {subtask?.task?.id && (
        <Breadcrumbs aria-label="breadcrumb" sx={{marginBottom: '1rem'}}>
          <Link underline="hover" color="inherit" component={RouterLink} to={RoutePaths.Main}>
            Главная
          </Link>
          <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            to={generatePath(RoutePaths.TaskPage, {taskId: `${subtask.task.id}`})}
          >
            {subtask.task.title}
          </Link>
          <Typography color="text.primary">{subtask?.title}</Typography>
        </Breadcrumbs>
      )}
      <header className={s.header}>
        <Stack direction="row" spacing={20} useFlexGap alignItems="center">
          <Typography variant="h1">
            Внутренняя Задача №<span className="link">{subtask?.ticket}</span>
          </Typography>
        </Stack>
        <Stack direction="row" spacing={5} useFlexGap alignItems="center">
          <DeadlineChip
            deadlineDelta={subtask?.deadlineDelta}
            deadlineAt={subtask?.deadlineAt}
            createdAt={subtask?.createdAt}
            doneAt={subtask?.doneAt}
          />
          <SubtaskStatusButton taskStatus={status} delayAt={subtask?.delayAt} />
        </Stack>
        {taskId && status && (
          <SubtaskHeaderControls
            taskId={taskId}
            status={status}
            isErrorTask={false}
            isDisabled={isError}
            departmentId={subtask?.departmentId}
            isUpdateLoading={isUpdateLoading}
            onChangeStatus={handleChangeStatus}
            error={errorType}
            isAuthor={isAuthor}
            isExecutor={!isAuthor}
          />
        )}
      </header>
      <section className={s.section}>
        <Stack
          direction="row"
          spacing={20}
          useFlexGap
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h3" gutterBottom={false} className={s.title}>
            {!isSuccess && <Skeleton variant="rounded" width={210} height={24} />}
            {isSuccess && subtask?.title}
          </Typography>
        </Stack>
        {isError && <span className="error">Ошибка. Задача не найдена</span>}
        {!isError && (
          <>
            <TaskSettingsSection
              task={subtask}
              controls={['noEditCompany', 'pin']}
              onUpdateTask={handleUpdateTask}
            />
            <Stack
              direction="row"
              spacing={10}
              useFlexGap
              alignItems="center"
              justifyContent="space-between"
            >
              <TaskSettingsSection
                task={subtask}
                controls={['department', 'taskType', 'user', 'author']}
                onUpdateTask={handleUpdateTask}
              />

              <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                {subtask?.isHasFiles && taskId && <DownloadAllButton taskId={taskId} />}
                <DisplaySystemCommentsButton
                  onClick={handleDisplaySystemCommentsButton}
                  isDisplaySystemComments={isDisplaySystemComments}
                />
              </Stack>
            </Stack>
            {taskId && (
              <SubtaskChatWrapper
                taskId={taskId}
                isLoading={isLoading}
                subtaskComments={subtask?.subtaskComments}
                status={status}
                isDisplaySystemComments={isDisplaySystemComments}
              />
            )}
          </>
        )}
      </section>
    </div>
  );
};

export default SubtaskPage;
