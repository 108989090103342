import {useCallback, useEffect, useState} from 'react';

import {useLazyGetCompaniesQuery} from '@/stores/api/companies';
import {EFilterName, IOption} from '@/shared/models/tasksFilterModel';
import {TPaginationModel} from '@/shared/models/tableModel.ts';
import {PAGE_SIZE} from '@/scenes/AllTasksPage/scenes/constants.ts';

type TUpdate = (key: EFilterName, options: IOption[]) => void;

export const useCompanyFilters = (onUpdate: TUpdate) => {
  const [paginationModel] = useState<TPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE
  });

  const [trigger, {data: companiesData}] = useLazyGetCompaniesQuery();

  const handleLoadCompanies = useCallback(
    (search?: string) => {
      const query: Record<string, any> = {
        'filters[without_inner_support_companies]': 1,
        'filters[active]': 1
      };

      if (search && search.trim().length > 0) {
        query.find = search;
      } else {
        query.page = {
          limit: paginationModel.pageSize,
          offset: paginationModel.page === 0 ? 0 : paginationModel.page * paginationModel.pageSize
        };
      }

      trigger(query);
    },
    [trigger, paginationModel]
  );

  useEffect(() => {
    if (companiesData) {
      onUpdate(EFilterName.CompanyId, companiesData.data);
    }
  }, [companiesData, onUpdate]);

  return {handleLoadCompanies};
};
