import {useEffect} from 'react';

import {useUserInfoQuery} from '@/stores/api/user';
import {
  useGetAllDepartmentsQuery,
  useGetAllSourcesQuery,
  useGetAllStatusesQuery,
  useGetAllTypesQuery,
  useGetAllUnitsQuery,
  useGetCompanyTypesQuery
} from '@/stores/api/dictionaries';
import useLinearLoading from '@/shared/hooks/useLinearLoading';
import {useAppDispatch, useAppSelector} from '@/stores/hooks';
import {userInfoSelector} from '@/stores/AuthStore/AuthStateSelector';
import {individualTypeName} from '@/shared/constants/companyConstants';
import {setIndividualTypeId} from '@/stores/TaskConditionsStore';
import {ICompany} from '@/shared/models/companyModel';
import {setUserInfo} from '@/stores/AuthStore';

interface IProps {
  skip?: boolean;
}

export const useMetaInfo = ({skip}: IProps) => {
  const dispatch = useAppDispatch();
  /**
   * LOAD dictionaries
   */
  const currentUser = useAppSelector(userInfoSelector);

  const {data: userInfo} = useUserInfoQuery(undefined, {
    skip: skip || !!currentUser
  });

  const {isLoading: isStatusesLoading} = useGetAllStatusesQuery({}, {skip});
  const {isLoading: isDepartmentsLoading} = useGetAllDepartmentsQuery({}, {skip});
  const {isLoading: isTypesLoading} = useGetAllTypesQuery({}, {skip});
  const {isLoading: isSourcesLoading} = useGetAllSourcesQuery({}, {skip});
  const {isLoading: isUnitsLoading} = useGetAllUnitsQuery({}, {skip});
  const {data: companyTypesData, isSuccess} = useGetCompanyTypesQuery({}, {skip});

  const isLoading =
    isStatusesLoading ||
    isDepartmentsLoading ||
    isTypesLoading ||
    isSourcesLoading ||
    isUnitsLoading;

  useEffect(() => {
    if (userInfo) {
      dispatch(setUserInfo(userInfo));
    }
  }, [dispatch, userInfo]);

  useLinearLoading(isLoading);

  useEffect(() => {
    if (companyTypesData?.data) {
      const individualType = companyTypesData.data.find(
        ({name}: ICompany) => name === individualTypeName
      );
      dispatch(setIndividualTypeId(individualType?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return null;
};
