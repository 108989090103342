import React, {FC, memo, useState} from 'react';
import {Button, CircularProgress} from '@mui/material';

import {downloadFile} from '@/shared/utils/downloadFiles';
import {ReactComponent as DownloadIcon} from '@/assets/cloud-download.svg';
import {useLazyGetZipFilesSupportTaskQuery} from '@/stores/api/support-task-page/support-task-page.ts';

interface IProps {
  taskId: string;
}

const DownloadAllButton: FC<IProps> = ({taskId}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [triggerGetZipFilesSupportTask] = useLazyGetZipFilesSupportTaskQuery();

  const handleGetFiles = async () => {
    setIsLoading(true);

    try {
      const response = await triggerGetZipFilesSupportTask({taskId: taskId.toString()}).unwrap();

      if (response) {
        await downloadFile(response);
      }
    } catch (error) {
      console.error('Ошибка при загрузке файлов:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      startIcon={isLoading ? <CircularProgress color="inherit" size={24} /> : <DownloadIcon />}
      variant="text"
      onClick={handleGetFiles}
      disabled={isLoading}
    >
      {isLoading ? 'Скачивание архива...' : 'Скачать все файлы'}
    </Button>
  );
};

export default memo(DownloadAllButton);
