import React, {FC, memo, useCallback} from 'react';
import {Button} from '@mui/material';
import {useSelector} from 'react-redux';

import {useAppDispatch, useAppSelector} from '@/stores/hooks';
import {
  preparedFiltersSelector,
  tasksDateFilterSelector,
  tasksFilterHasValuesSelector,
  tasksFilterIsUpdatedSelector,
  tasksFilterSelector
} from '@/stores/TasksFilterStore/TasksFilterStateSelector';
import {EAdditionalValues, EFilterName, IOption} from '@/shared/models/tasksFilterModel';
import {
  resetState,
  toggleUpdate,
  updateDatesValueByKey,
  updateListOptionByKey,
  updateTempValueByKey,
  updateValueByKey
} from '@/stores/TasksFilterStore';
import TasksFilterItems from '@/scenes/AllTasksPage/components/TasksFilters/TasksFilterItems';
import TasksFilterMultiItems from '@/scenes/AllTasksPage/components/TasksFilters/TasksFilterMultiItems';
import {useDepartmentFilters} from '@/scenes/AllTasksPage/components/TasksFilters/hooks/useDepartmentFilters';
import {useTaskTypesFilters} from '@/scenes/AllTasksPage/components/TasksFilters/hooks/useTaskTypesFilters';
import {useHeadFilters} from '@/scenes/AllTasksPage/components/TasksFilters/hooks/useHeadFilters';
import {useCompanyFilters} from '@/scenes/AllTasksPage/components/TasksFilters/hooks/useCompanyFilters';
import {useStatusFilters} from '@/scenes/AllTasksPage/components/TasksFilters/hooks/useStatusFilters';
import {useSourceFilters} from '@/scenes/AllTasksPage/components/TasksFilters/hooks/useSourceFilters';
import {useUserFilters} from '@/scenes/AllTasksPage/components/TasksFilters/hooks/useUserFilters';
import {setCounterState, setSearchState} from '@/stores/SearchStateStore';
import {RootState} from '@/stores';
import {DateRangePicker} from '@/components/UIKit/DateRangePicker';

import s from './TasksFilters.module.css';

interface IProps {}

const TasksFilters: FC<IProps> = () => {
  const dispatch = useAppDispatch();

  const handleUpdate = useCallback(
    (key: EFilterName, options: IOption[]) => {
      dispatch(updateListOptionByKey({key, options}));
    },
    [dispatch]
  );

  const {handleLoadDepartments} = useDepartmentFilters(handleUpdate);
  const {handleLoadTaskTypes} = useTaskTypesFilters(handleUpdate);
  const {handleLoadHeads} = useHeadFilters(handleUpdate);
  const {handleLoadCompanies} = useCompanyFilters(handleUpdate);
  const {handleLoadStatuses} = useStatusFilters(handleUpdate);
  const {handleLoadSources} = useSourceFilters(handleUpdate);
  const {handleLoadUsers} = useUserFilters(handleUpdate, true);

  const lazyLoaders: Partial<Record<EFilterName, () => void>> = {
    [EFilterName.Departments]: handleLoadDepartments,
    [EFilterName.Types]: handleLoadTaskTypes,
    [EFilterName.Head]: handleLoadHeads,
    [EFilterName.CompanyId]: handleLoadCompanies,
    [EFilterName.Statuses]: handleLoadStatuses,
    [EFilterName.Sources]: handleLoadSources,
    [EFilterName.Users]: handleLoadUsers
  };

  /**
   * LIST FILTERS
   */
  const appealsFilters = useAppSelector(tasksFilterSelector);
  const hasFilters = useAppSelector(tasksFilterHasValuesSelector);
  const isUpdated = useAppSelector(tasksFilterIsUpdatedSelector);
  const dateFilter = useAppSelector(tasksDateFilterSelector);
  const preparedParams = useAppSelector(preparedFiltersSelector);

  /**
   * STATE MANAGEMENT
   */

  const handleChange = (key?: EFilterName, value?: IOption | IOption[]) => {
    if (!key) {
      return;
    }
    // TODO: сделать для сброса значения
    if (Array.isArray(value)) {
      const ids = value ? value.map(o => o.id).join(',') : '';
      dispatch(updateTempValueByKey({key, value: ids}));
    }

    if (!Array.isArray(value)) {
      // @ts-ignore
      dispatch(updateTempValueByKey({key, value: value?.id ?? ''}));
    }

    dispatch(toggleUpdate(true));
  };

  const handleChangeDate = (fieldName: EFilterName, range?: {start?: string; end?: string}) => {
    dispatch(
      updateDatesValueByKey({
        key: fieldName,
        value: range
      })
    );

    dispatch(toggleUpdate(true));
  };

  const handleSubmit = () => {
    if (preparedParams) {
      // SUBMIT filters
      Object.entries(preparedParams).map(([key, value]) => {
        if (value !== undefined) {
          dispatch(
            updateValueByKey({
              // @ts-ignore
              key,
              value
            })
          );
        }
      });

      // сбросим значение page на 0 на сабмите
      dispatch(
        updateValueByKey({
          key: EAdditionalValues.Page,
          value: '0'
        })
      );
    }
    dispatch(toggleUpdate(false));
  };

  const handleReset = () => {
    dispatch(resetState());
    dispatch(setSearchState(''));
    dispatch(setCounterState(undefined));
  };

  const counter = useSelector((state: RootState) => state.searchState.counter);

  return (
    <div className={s.wrapper}>
      <div className={s.filtersWrapper}>
        {Object.values(appealsFilters).map(
          ({fieldName, isMultiply, defaultValue: _val, type: _types, ...rest}) => {
            // Если для данного фильтра есть функция onOpen, получаем её из маппинга
            const onOpenHandler = lazyLoaders[fieldName as EFilterName];

            if (_types === 'date' && fieldName) {
              return (
                <DateRangePicker
                  key={fieldName}
                  placeholder={rest.fieldLabel || ''}
                  start={dateFilter[fieldName]?.start}
                  end={dateFilter[fieldName]?.end}
                  onChange={val => handleChangeDate(fieldName, val)}
                />
              );
            }
            if (isMultiply) {
              return (
                <TasksFilterMultiItems
                  key={fieldName}
                  fieldName={fieldName}
                  onChange={val => handleChange(fieldName, val)}
                  {...(onOpenHandler ? {onOpen: onOpenHandler} : {})}
                  {...rest}
                />
              );
            }
            return (
              <TasksFilterItems
                key={fieldName}
                fieldName={fieldName}
                onChange={val => handleChange(fieldName, val)}
                {...(onOpenHandler ? {onOpen: onOpenHandler} : {})}
                {...rest}
              />
            );
          }
        )}
        {isUpdated && (
          <Button variant="text" size="small" sx={{marginBottom: '1.2rem'}} onClick={handleSubmit}>
            Применить
          </Button>
        )}
        {(hasFilters || counter !== undefined) && (
          <Button variant="text" size="small" sx={{marginBottom: '1.2rem'}} onClick={handleReset}>
            Сбросить
          </Button>
        )}
      </div>
    </div>
  );
};

export default memo(TasksFilters);
