import {api} from '@/stores/api/index';
import {
  IDepartment,
  ISource,
  IStatus,
  IType,
  ITypeResponse,
  IUnit,
  IDepartmentResponse,
  ICompanyType
} from '@/shared/models/dictionaryModel';
import {ICommonArgs, ICommonResponse, IPagination} from '@/shared/models/commonModel';
import {formatKeys} from '@/shared/utils/responseUtils';

interface IQueryArg extends ICommonArgs {}

const defaultParams = {
  fields: 'id,name',
  sort: 'id'
};

export const apiWithDictionaries = api.injectEndpoints({
  endpoints: builder => ({
    getAllDepartments: builder.query<IDepartment[], IQueryArg>({
      query: () => ({
        url: '/v1/dictionaries/departments/',
        method: 'get',
        params: {
          include: 'unit_department',
          filters: {
            is_disabled: 0
          },
          fields: defaultParams.fields + ',is_cross_unit'
        }
      }),
      transformResponse(returnValue?: ICommonResponse<IDepartmentResponse[]>): IDepartment[] {
        if (!returnValue?.data?.length) {
          return [];
        }
        return returnValue.data.map((item: IDepartmentResponse) => {
          return formatKeys<IDepartmentResponse, IDepartment>(item);
        });
      }
    }),
    getAllStatuses: builder.query<IStatus[], IQueryArg>({
      query: () => ({
        url: '/v1/dictionaries/task_statuses/',
        method: 'get',
        params: defaultParams
      }),
      transformResponse(returnValue?: {data: IStatus[]}): IStatus[] {
        return returnValue?.data || [];
      }
    }),
    getAllSources: builder.query<ISource[], IQueryArg>({
      query: () => ({
        url: '/v1/dictionaries/task_sources/',
        method: 'get',
        params: defaultParams
      }),
      transformResponse(returnValue?: {data: ISource[]}): ISource[] {
        return returnValue?.data || [];
      }
    }),
    getAllTypes: builder.query<IType[], IQueryArg>({
      query: params => ({
        url: '/v1/dictionaries/task_types/',
        method: 'get',
        params: {
          fields: 'id,name,is_subtask,is_task,is_support_task',
          ...params
        }
      }),
      transformResponse(returnValue?: ICommonResponse<ITypeResponse[]>): IType[] {
        if (!returnValue?.data?.length) {
          return [];
        }
        return returnValue.data.map((item: ITypeResponse) => {
          return formatKeys<ITypeResponse, IType>(item);
        });
      }
    }),
    getAllUnits: builder.query<IUnit[], IQueryArg>({
      query: () => ({
        url: '/v1/dictionaries/units/',
        method: 'get',
        params: defaultParams
      }),
      transformResponse(returnValue?: {data: IUnit[]}): IUnit[] {
        return returnValue?.data || [];
      }
    }),
    getCompanyTypes: builder.query<IPagination<ICompanyType[]>, IQueryArg>({
      query: () => ({
        url: '/v1/dictionaries/company_types/',
        method: 'get',
        params: defaultParams
      })
    })
  }),
  overrideExisting: true
});

export const {
  useGetAllStatusesQuery,
  useGetAllDepartmentsQuery,
  useLazyGetAllDepartmentsQuery,
  useGetAllTypesQuery,
  useLazyGetAllTypesQuery,
  useGetAllSourcesQuery,
  useLazyGetAllSourcesQuery,
  useGetAllUnitsQuery,
  useGetCompanyTypesQuery
} = apiWithDictionaries;

export const allDepartmentsQueryState =
  apiWithDictionaries.endpoints.getAllDepartments.useQueryState;
export const allStatusesQueryState = apiWithDictionaries.endpoints.getAllStatuses.useQueryState;
export const allSourcesQueryState = apiWithDictionaries.endpoints.getAllSources.useQueryState;
export const allTypesQueryState = apiWithDictionaries.endpoints.getAllTypes.useQueryState;
export const allUnitsQueryState = apiWithDictionaries.endpoints.getAllUnits.useQueryState;
