import {createSelector} from '@reduxjs/toolkit';

import {RootState} from '@/stores';
import {
  EFilterName,
  IDateRange,
  IFilterOption,
  IOption,
  TDateFilterRange,
  TFilterValues,
  TTempFilterValues
} from '@/shared/models/tasksFilterModel';

export const subtasksFilterSelector = (state: RootState) => {
  return state.subtasksFilter.filterList as Record<EFilterName, IFilterOption<IOption>>;
};

export const tempSubtasksFilterValuesSelector = (state: RootState) => {
  return state.subtasksFilter.tempFilterValues as TTempFilterValues;
};

export const subtasksDateFilterSelector = (state: RootState) => {
  return state.subtasksFilter.filterListDates as TDateFilterRange;
};

export const subtasksFilterValuesSelector = (state: RootState) => {
  return state.subtasksFilter.filterValues;
};

export const subtasksFilterIsFetchSelector = (state: RootState) => {
  return state.subtasksFilter.isFetched;
};

export const subtasksFilterIsUpdatedSelector = (state: RootState) => {
  return state.subtasksFilter.isUpdated;
};

export const subtasksFilterHasValuesSelector = createSelector(
  subtasksFilterValuesSelector,
  (values?: TFilterValues) => {
    if (!values) {
      return false;
    }
    return Object.entries(values).filter(([_key, val]) => !!val).length > 0;
  }
);

export const preparedFiltersSelector = createSelector(
  tempSubtasksFilterValuesSelector,
  subtasksDateFilterSelector,
  (commonFilters, dateFilters = {}) => {
    const formatDateToString = Object.entries(dateFilters).reduce(
      (acc, curr: [string, IDateRange | undefined]) => {
        if (curr[1] && curr[1].start && curr[1].end) {
          return {
            ...acc,
            [`${curr[0]}`]: `between|${curr[1].start},${curr[1].end}`
          };
        }
        return {
          ...acc,
          [`${curr[0]}`]: ''
        };
      },
      {}
    );

    return {
      ...commonFilters,
      ...formatDateToString
    };
  }
);
