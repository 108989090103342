import {createSelector} from '@reduxjs/toolkit';

import {RootState} from '@/stores';
import {
  EFilterName,
  IDateRange,
  IFilterOption,
  IOption,
  TDateFilterRange,
  TFilterValues,
  TTempFilterValues
} from '@/shared/models/tasksFilterModel';

export const supportTasksFilterSelector = (state: RootState) => {
  return state.supportTasksFilter.filterList as Record<EFilterName, IFilterOption<IOption>>;
};

export const tempSupportTasksFilterValuesSelector = (state: RootState) => {
  return state.supportTasksFilter.tempFilterValues as TTempFilterValues;
};

export const supportTasksDateFilterSelector = (state: RootState) => {
  return state.supportTasksFilter.filterListDates as TDateFilterRange;
};

export const supportTasksFilterValuesSelector = (state: RootState) => {
  return state.supportTasksFilter.filterValues;
};

export const supportTasksFilterIsFetchSelector = (state: RootState) => {
  return state.supportTasksFilter.isFetched;
};

export const supportTasksFilterIsUpdatedSelector = (state: RootState) => {
  return state.supportTasksFilter.isUpdated;
};

export const supportTasksFilterHasValuesSelector = createSelector(
  supportTasksFilterValuesSelector,
  (values?: TFilterValues) => {
    if (!values) {
      return false;
    }
    return Object.entries(values).filter(([_key, val]) => !!val).length > 0;
  }
);

export const preparedFiltersSelector = createSelector(
  tempSupportTasksFilterValuesSelector,
  supportTasksDateFilterSelector,
  (commonFilters, dateFilters = {}) => {
    const formatDateToString = Object.entries(dateFilters).reduce(
      (acc, curr: [string, IDateRange | undefined]) => {
        if (curr[1] && curr[1].start && curr[1].end) {
          return {
            ...acc,
            [`${curr[0]}`]: `between|${curr[1].start},${curr[1].end}`
          };
        }
        return {
          ...acc,
          [`${curr[0]}`]: ''
        };
      },
      {}
    );

    return {
      ...commonFilters,
      ...formatDateToString
    };
  }
);
