import {useEffect, useCallback} from 'react';

import {useLazyGetUsersQuery} from '@/stores/api/user';
import {EFilterName, IOption} from '@/shared/models/tasksFilterModel';

type TUpdate = (key: EFilterName, options: IOption[]) => void;

export const useHeadFilters = (onUpdate: TUpdate) => {
  const [trigger, {data: users}] = useLazyGetUsersQuery();

  const handleLoadHeads = useCallback(() => {
    if (!users) {
      trigger({
        'filters[heads]': true,
        'filters[active]': 1
      });
    }
  }, [users, trigger]);

  useEffect(() => {
    if (users) {
      onUpdate(EFilterName.Head, users);
    }
  }, [users, onUpdate]);

  return {handleLoadHeads};
};
