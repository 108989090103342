import {useCallback, useEffect, useState} from 'react';

import {useLazyGetUsersQuery} from '@/stores/api/user';
import {EFilterName, IOption} from '@/shared/models/tasksFilterModel';
import {TPaginationModel} from '@/shared/models/tableModel.ts';
import {PAGE_SIZE} from '@/scenes/AllTasksPage/scenes/constants.ts';

type TUpdate = (key: EFilterName, options: IOption[]) => void;

export const useUserFilters = (onUpdate: TUpdate, isTaskPage?: boolean) => {
  const [paginationModel] = useState<TPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE
  });

  const [trigger, {data: users}] = useLazyGetUsersQuery();

  const handleLoadUsers = useCallback(
    (search?: string) => {
      const query: Record<string, any> = {};

      if (search && search.trim().length > 0) {
        query.find = search;
      } else {
        query.page = {
          limit: paginationModel.pageSize,
          offset: paginationModel.page === 0 ? 0 : paginationModel.page * paginationModel.pageSize
        };
      }

      trigger(query);
    },
    [trigger, paginationModel]
  );

  useEffect(() => {
    if (users) {
      onUpdate(EFilterName.Users, users);
      if (!isTaskPage) {
        onUpdate(EFilterName.Author, users);
      }
    }
  }, [users, onUpdate, isTaskPage]);

  return {handleLoadUsers};
};
