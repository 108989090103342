import {IFile} from '@/shared/models/fileModel';
import {fileEmailConfig, fileMobileConfig} from '@/shared/constants/file';

export const checkSize = (files: IFile[], isSendEmail?: boolean) => {
  const sumSize = files.reduce((acc, curr) => {
    if (curr.size) {
      return acc + curr.size;
    }
    return acc;
  }, 0);

  const maxFileSize = isSendEmail
    ? fileEmailConfig.maxTaskFileSize
    : fileMobileConfig.maxTaskFileSize;

  if (sumSize > maxFileSize) {
    return `Суммарный объем вложений больше ${maxFileSize / (1024 * 1024)} мегабайт.`;
  }

  return null;
};
