import {Draft} from '@reduxjs/toolkit';

import {api} from '@/stores/api';
import {IFile, IFileResponse} from '@/shared/models/fileModel';
import {ISubtask} from '@/shared/models/subtaskModel';
import {ICommonResponse, IPagination} from '@/shared/models/commonModel';
import {IMessage} from '@/shared/models/messageModel';
import {formatKeys} from '@/shared/utils/responseUtils.ts';

interface ICreateTaskCommentArg {
  taskId: string;
  content: string;
  attachments?: IFile[];
}

export const apiWithSubtaskComment = api.injectEndpoints({
  endpoints: builder => ({
    createSubtaskComment: builder.mutation<IPagination<ISubtask>, ICreateTaskCommentArg>({
      query: ({taskId, content, attachments}) => ({
        url: `/v1/subtasks/${taskId}/subtask_comments`,
        method: 'post',
        data: {
          content,
          attachments
        }
      }),
      // @ts-ignore
      invalidatesTags: (_result, _error, id) => {
        return [{type: 'SUBTASK', id}];
      },
      async onQueryStarted({taskId, ...patch}, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          // @ts-ignore
          api.util.updateQueryData('getSubtaskById', {taskId}, (draft: Draft<ISubtask>) => {
            const comment: IMessage = {
              id: -1,
              taskId: Number(taskId),
              createdAt: new Date().toUTCString(),
              isSending: true,
              direction: false,
              ...patch
            };

            if (draft.subtaskComments?.length) {
              draft.subtaskComments.push(comment);
            } else {
              draft.subtaskComments = [comment];
            }
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();

          /**
           * Alternatively, on failure you can invalidate the corresponding cache tags
           * to trigger a re-fetch:
           * dispatch(api.util.invalidateTags(['Post']))
           */
        }
      }
    }),
    getZipFilesSubtaskComment: builder.query<IFile, {taskId: string; taskCommentId: string}>({
      query: ({taskId, taskCommentId}) => ({
        url: `/v1/subtasks/${taskId}/subtask_comments/${taskCommentId}/files/zip`,
        method: 'GET'
      }),
      transformResponse: (result: ICommonResponse<IFileResponse>): IFile =>
        formatKeys<IFileResponse, IFile>(result.data)
    })
  }),
  overrideExisting: true
});

export const {useCreateSubtaskCommentMutation, useLazyGetZipFilesSubtaskCommentQuery} =
  apiWithSubtaskComment;
