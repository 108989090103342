import {api} from '@/stores/api';
import {
  ICommonArgs,
  ICommonResponse,
  IMeta,
  IMetaResponse,
  IPagination
} from '@/shared/models/commonModel';
import {formatKeys} from '@/shared/utils/responseUtils';
import {authorFields, companyFields, userFields} from '@/shared/utils/requestParamsUtils';
import {ISubtask, ISubtaskResponse} from '@/shared/models/subtaskModel';

interface IQueryArg extends ICommonArgs {}
export const apiWithSubtasksTable = api.injectEndpoints({
  endpoints: builder => ({
    getSubtasksTable: builder.query<IPagination<ISubtask[]>, IQueryArg>({
      query: params => ({
        url: '/v1/subtasks/',
        method: 'get',
        params: {
          appends: 'deadline_delta',
          sort: 'active_subtasks',
          include: ['company,user,author,'].join(','),
          fields: [
            'id,title,ticket,department_id,created_at,updated_at,done_at,deadline_at,task_status_id,task_type_id,unit_id,is_has_files,task_id',
            companyFields,
            userFields,
            authorFields
          ]
            .filter(Boolean)
            .join(','),
          ...params
        }
      }),
      transformResponse: (result: ICommonResponse<ISubtaskResponse[]>): IPagination<ISubtask[]> => {
        return {
          meta: formatKeys<IMetaResponse, IMeta>(result.meta),
          data: result.data.map(item => {
            return formatKeys<ISubtaskResponse, ISubtask>(item);
          })
        };
      },
      // @ts-ignore
      providesTags: result => {
        return result && result?.data
          ? [...result.data.map(({id}) => ({type: 'SUBTASKS', id})), {type: 'TASKS', id: 'LIST'}]
          : [{type: 'SUBTASKS', id: 'LIST'}];
      }
    })
  }),
  overrideExisting: true
});

export const {useGetSubtasksTableQuery} = apiWithSubtasksTable;
