import React, {FC} from 'react';
import {Stack, Typography} from '@mui/material';

import {useAppSelector} from '@/stores/hooks.ts';
import {userInfoSelector} from '@/stores/AuthStore/AuthStateSelector.ts';

interface IProps {}

const AnalyticsPage: FC<IProps> = () => {
  const currentUser = useAppSelector(userInfoSelector);
  const isHead = currentUser?.isHead;
  const isHeadUnit = currentUser?.isHeadUnit;

  if (!isHead && !isHeadUnit) {
    return (
      <div className="scene">
        <Typography variant="h2" align="center" sx={{marginTop: '2rem'}}>
          Страница не существует. Попробуйте обновить страницу или вернуться позже.
        </Typography>
      </div>
    );
  }

  return (
    <div className="scene" style={{display: 'flex'}}>
      <header className="scene-header">
        <Typography variant="h1" gutterBottom sx={{marginBottom: '2rem'}}>
          Аналитика
        </Typography>
      </header>
      <Stack direction="column" spacing={4} sx={{flex: 1}}>
        <iframe
          title={'Аналитика задач'}
          src="https://redash.infra.peb.guru/embed/query/108/visualization/120?hide_link&api_key=9DhlmWfUkBdS7rGehVQYpi3TEH4Fusy4qVHfSS4f"
          style={{minWidth: '100%', minHeight: '100%'}}
          scrolling="no"
        ></iframe>
        <iframe
          title={'Оценки задач'}
          src="https://redash.infra.peb.guru/embed/query/109/visualization/121?hide_link&api_key=9DhlmWfUkBdS7rGehVQYpi3TEH4Fusy4qVHfSS4f"
          style={{minWidth: '100%', minHeight: '100%'}}
          scrolling="no"
        ></iframe>
      </Stack>
    </div>
  );
};

export default AnalyticsPage;
