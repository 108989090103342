import React, {FC, memo, useMemo} from 'react';
import cn from 'classnames';
import {CircularProgress, Typography} from '@mui/material';

import {IFile} from '@/shared/models/fileModel';
import {convertToDataStorageUnit} from '@/shared/utils/mathUtils';
import {ReactComponent as FileIcon} from '@/assets/file.svg';
import {ReactComponent as DownloadIcon} from '@/assets/download.svg';
import {TelegramSticker} from '@/components/TelegramSticker';

import s from './ChatFile.module.css';

interface IProps {
  isSending?: boolean;
  file: IFile;
  onGetFile: (file: IFile) => void;
}

const ChatFile: FC<IProps> = ({file, isSending, onGetFile}) => {
  const sizeFormatted = useMemo(() => {
    if (!file?.size) {
      return '';
    }
    return convertToDataStorageUnit(file.size, true, 1);
  }, [file?.size]);

  if (!file) {
    return null;
  }

  const isAudio = file.mimeType && file.mimeType.startsWith('audio/');

  if (isAudio) {
    return (
      <div className={cn(s.audioContainer, s.audioBorder)}>
        <audio controls src={file.publicUrl}>
          <track default kind="captions" srcLang="ru" label="Русские субтитры" />
          Ваш браузер не поддерживает воспроизведение аудио.
        </audio>
      </div>
    );
  }

  const isSticker = file.mimeType && file.mimeType === 'image/webp';

  // TODO: разобраться с cors политикой s3 хранилища для получения анимированных стикеров
  const isAnimatedSticker = file.name.toLowerCase().endsWith('.tgs');

  if (file.publicUrl && isSticker) {
    return <TelegramSticker stickerUrl={file.publicUrl} isAnimated={isAnimatedSticker} />;
  }

  return (
    <button
      className={cn(s.file, s.fileButton, {[s.disabled]: isSending})}
      onClick={() => onGetFile(file)}
      title={file.name}
    >
      <div className={s.fileIcon}>
        <FileIcon />
      </div>
      <div className={s.fileInfo}>
        <Typography variant="bold" title={file.name} className={s.fileInfoText}>
          {file.name}
        </Typography>
        <span className={s.fileSize}>{sizeFormatted}</span>
      </div>
      <div className={s.fileDownload}>
        {isSending ? <CircularProgress color="inherit" size={24} /> : <DownloadIcon />}
      </div>
    </button>
  );
};

export default memo(ChatFile);
