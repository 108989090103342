import React, {FC, useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {Checkbox, createFilterOptions} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import cn from 'classnames';

import {ComboBox} from '@/components/UIKit';
import {EFilterName, IFilterOption, IOption} from '@/shared/models/tasksFilterModel';
import {checkCompanyIndividual} from '@/shared/utils/companyUtils.ts';
import {useAppSelector} from '@/stores/hooks.ts';
import {companyIndividualTypeIdSelector} from '@/stores/TaskConditionsStore/TaskConditionsSelector.ts';
import {useDebounce} from '@/shared/hooks/useDebounce.ts';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const selectAllOption = {name: 'Все', id: 'select-all', isActive: true};

interface IProps extends IFilterOption<IOption> {
  onChange: (value?: IOption[]) => void;
  onOpen?: (search?: string) => void;
}

const TasksFilterMultiItems: FC<IProps> = ({
  fieldName,
  selectAll,
  options,
  onChange,
  minWidth,
  onOpen,
  ...rest
}) => {
  const [urlParams] = useSearchParams();
  const companyIndividualType = useAppSelector(companyIndividualTypeIdSelector);
  const [selectedOptions, setSelectedOptions] = useState<IOption[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const debouncedInput = useDebounce(inputValue, 400);

  const idsFromUrl = fieldName && urlParams.get(fieldName);

  useEffect(() => {
    if (onOpen && isOpen) {
      const alreadyExists = options.some(option =>
        option.name.toLowerCase().includes(debouncedInput.toLowerCase())
      );
      if (!alreadyExists || !debouncedInput) {
        onOpen(debouncedInput);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInput]);

  useEffect(() => {
    if (selectAll && options) {
      if (idsFromUrl) {
        const foundOptions = options.filter(
          val => val.id && idsFromUrl.split(',').includes(String(val.id))
        );
        setSelectedOptions(foundOptions.length > 0 ? foundOptions : [selectAllOption]);
      } else {
        setSelectedOptions([selectAllOption]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, idsFromUrl, selectAll]);

  const allSelected = selectedOptions.some(option => option.id === selectAllOption.id);

  const getOptionLabel = (option: IOption) => `${option?.name}`;

  const handleToggleSelectAll = () => {
    if (allSelected) {
      setSelectedOptions([]);
      onChange([]);
    } else {
      setSelectedOptions([selectAllOption]);
      onChange(undefined);
    }
  };

  const handleClearOptions = () => {
    setSelectedOptions([]);
    onChange([]);
  };

  const handleToggleOption = (newSelection: IOption[]) => {
    const filtered = newSelection.filter(option => option.id !== selectAllOption.id);
    setSelectedOptions(filtered);
  };

  const handleChange = (
    _event: React.SyntheticEvent,
    value: IOption | IOption[],
    reason: string
  ) => {
    if (Array.isArray(value)) {
      if (reason === 'selectOption' || reason === 'removeOption') {
        if (value.some(option => option.id === selectAllOption.id)) {
          handleToggleSelectAll();
        } else {
          handleToggleOption(value);
          onChange(value);
        }
      } else if (reason === 'clear') {
        handleClearOptions();
      }
    }
  };

  /**
   * RENDER
   */
  const renderOption = (props: any, option: IOption, {selected}: {selected: boolean}) => {
    if (!option) {
      return null;
    }

    const selectAllProps =
      option.id === selectAllOption.id // To control the state of 'select-all' checkbox
        ? {checked: allSelected}
        : {};

    const {className, ...rest} = props;
    const companyTypeId = 'companyTypeId' in option ? option.companyTypeId : undefined;
    const isNotActiveUser = fieldName === EFilterName.Users && !option?.isActive;
    const isImp =
      isNotActiveUser || checkCompanyIndividual(companyIndividualType, companyTypeId, option.name);

    return (
      <li {...rest} className={cn(className, isImp ? 'imp' : '')} key={option.id}>
        <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} {...selectAllProps} />
        {getOptionLabel(option)}
      </li>
    );
  };

  const filter = createFilterOptions<IOption>({
    trim: true
  });

  return (
    <ComboBox<IOption>
      {...rest}
      key={fieldName}
      options={options}
      multiple
      variantInput="standard"
      // @ts-ignore
      onChange={handleChange}
      onOpen={() => {
        setIsOpen(true);
        onOpen && onOpen();
      }}
      onClose={() => {
        setIsOpen(false);
        setInputValue('');
        if (!selectedOptions.length) {
          handleToggleSelectAll();
        }
      }}
      onInputChange={(_event, newInputValue, reason) => {
        if (reason !== 'reset') {
          setInputValue(newInputValue);
        }
      }}
      inputValue={inputValue}
      disableCloseOnSelect
      sx={{
        marginRight: '2.4rem',
        marginBottom: '1.2rem',
        minWidth: minWidth ? `${minWidth}px` : '180px',
        maxWidth: '260px',
        position: 'relative',
        boxSizing: 'border-box',
        zIndex: 1
      }}
      disableClearable
      // @ts-ignore
      value={selectedOptions}
      renderOption={renderOption}
      renderTags={(values: IOption[], _getTagProps) => {
        // Если выбрана опция "Все", показываем её название
        if (!isOpen) {
          if (values.some(val => val.id === selectAllOption.id)) {
            return selectAllOption.name;
          }
          return `${values.length} выбрано`;
        }
        return null;
      }}
      // @ts-ignore
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const filteredWithoutSelectAll = filtered.filter(
          option => option.id !== selectAllOption.id
        );
        const selectedItems = selectedOptions.filter(option => option.id !== selectAllOption.id);
        const combined = [
          ...selectedItems,
          ...filteredWithoutSelectAll.filter(
            option => !selectedItems.some(selected => selected.id === option.id)
          )
        ];
        return [selectAllOption, ...combined];
      }}
    />
  );
};

export default TasksFilterMultiItems;
