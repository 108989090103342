import {api} from '@/stores/api';
import {
  ICommonArgs,
  ICommonResponse,
  IMeta,
  IMetaResponse,
  IPagination
} from '@/shared/models/commonModel';
import {formatKeys} from '@/shared/utils/responseUtils';
import {authorFields, companyFields, userFields} from '@/shared/utils/requestParamsUtils';
import {ISupportTask, ISupportTaskResponse} from '@/shared/models/supportTaskModel';

interface IQueryArg extends ICommonArgs {}

export const apiWithSupportTasks = api.injectEndpoints({
  endpoints: builder => ({
    getSupportTasks: builder.query<IPagination<ISupportTask[]>, IQueryArg>({
      query: params => ({
        url: '/v1/support_tasks/',
        method: 'get',
        params: {
          appends: 'deadline_delta',
          include: ['company,user,author'].join(','),
          fields: [
            'id,title,ticket,department_id,created_at,updated_at,done_at,deadline_at,task_status_id,task_type_id,unit_id,is_has_files',
            companyFields,
            userFields,
            authorFields
          ]
            .filter(Boolean)
            .join(','),
          ...params
        }
      }),
      transformResponse: (
        result: ICommonResponse<ISupportTaskResponse[]>
      ): IPagination<ISupportTask[]> => {
        return {
          meta: formatKeys<IMetaResponse, IMeta>(result.meta),
          data: result.data.map(item => {
            return formatKeys<ISupportTaskResponse, ISupportTask>(item);
          })
        };
      },
      // @ts-ignore
      providesTags: result => {
        return result && result?.data
          ? [
              ...result.data.map(({id}) => ({type: 'SUPPORT_TASKS', id})),
              {type: 'SUPPORT_TASKS', id: 'LIST'}
            ]
          : [{type: 'SUPPORT_TASKS', id: 'LIST'}];
      }
    })
  }),
  overrideExisting: true
});

export const {useGetSupportTasksQuery} = apiWithSupportTasks;
