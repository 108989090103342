import React, {FC, memo, useEffect, useMemo, useState} from 'react';
import {isToday} from 'date-fns';
import cn from 'classnames';
import {Button} from '@mui/material';
import isArray from 'lodash/isArray';
import RDateRangePicker from '@wojtekmaj/react-daterange-picker';

import {ReactComponent as ArrowIcon} from '@/assets/arrow.svg';

import {ReactComponent as CalendarIcon} from './assets/calendar.svg';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import s from './DateRangePicker.module.css';

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

interface IProps {
  placeholder: string;
  start?: string;
  end?: string;
  onChange?: (val?: {start?: string; end?: string}) => void;
  isOpenStraightaway?: boolean;
  singleDate?: boolean;
  isDelayTask?: boolean;
  setIsCalendarOpen?: (value: boolean) => void;
  minDate?: Date;
  maxDate?: Date;
  handleDateDelayChange?: (val: Value) => void;
  hideButton?: boolean;
}

const DateRangePicker: FC<IProps> = ({
  placeholder,
  start: _start,
  end: _end,
  onChange,
  isOpenStraightaway = false,
  singleDate = false,
  isDelayTask = false,
  setIsCalendarOpen,
  minDate,
  maxDate,
  handleDateDelayChange = () => {},
  hideButton
}) => {
  const [value, setValue] = useState<Value>([new Date(), new Date()]);
  const [isOpen, setIsOpen] = useState(isOpenStraightaway);

  const handleChange = (val: Value) => {
    let newValue: Value;

    if (isArray(val)) {
      newValue = val.map((date, index) => {
        if (date) {
          const year = date.getFullYear();
          const month = date.getMonth();
          const day = date.getDate();

          return index === 0
            ? new Date(year, month, day, 0, 0, 0)
            : new Date(year, month, day, 23, 59, 59);
        }
        return date;
      }) as Value;
    } else {
      if (val) {
        newValue = new Date(
          val.getFullYear(),
          val.getMonth(),
          val.getDate(),
          23,
          59,
          59
        ) as ValuePiece;
      } else {
        newValue = val;
      }
    }

    setValue(newValue);

    if (!newValue && onChange) {
      onChange();
      return;
    }
    if (isArray(newValue) && !isDelayTask && onChange) {
      onChange({
        start: singleDate ? undefined : newValue[0] ? newValue[0].toISOString() : undefined,
        end: newValue[1] ? newValue[1].toISOString() : undefined
      });
    }
    if (isDelayTask && setIsCalendarOpen) {
      handleDateDelayChange(newValue);
      setIsCalendarOpen(false);
    }
  };

  const showButton = useMemo(() => {
    if (hideButton) {
      return false;
    }
    if (isArray(value)) {
      const startToday = value && value[0] && isToday(value[0]);
      const endToday = value && value[1] && isToday(value[1]);
      return (startToday && endToday) || !value;
    }
    return true;
    // TODO: if only 1 date
  }, [hideButton, value]);

  useEffect(() => {
    if (_start && _end) {
      setValue([new Date(_start), new Date(_end)]);
    } else {
      setValue([new Date(), new Date()]);
    }
  }, [_end, _start]);

  const renderButton = () => {
    if (showButton) {
      return (
        <Button
          variant="contained"
          startIcon={<CalendarIcon fill={isDelayTask ? 'white' : '#556675'} />}
          endIcon={<ArrowIcon />}
          // @ts-ignore
          color={isDelayTask ? 'secondary' : 'background'}
          sx={{
            maxWidth: '235px',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1,
            padding: '7px',
            fontWeight: '400'
          }}
          onClick={() => setIsOpen(true)}
        >
          {placeholder}
        </Button>
      );
    }
    return null;
  };

  return (
    <div className={cn(s.wrapper, showButton && s.defaultView)}>
      {renderButton()}
      <RDateRangePicker
        isOpen={isOpen}
        onChange={handleChange}
        value={value}
        locale="ru-RU"
        minDate={minDate}
        maxDate={maxDate}
        calendarIcon={<CalendarIcon />}
        format="dd.MM.yyyy"
        shouldOpenCalendar={() => {
          setIsOpen(true);
          return true;
        }}
        shouldCloseCalendar={() => {
          setIsOpen(false);
          if (isDelayTask && setIsCalendarOpen) {
            setIsCalendarOpen(false);
          }
          return false;
        }}
        selectRange={!singleDate}
        returnValue={singleDate ? 'end' : 'range'}
      />
    </div>
  );
};

export default memo(DateRangePicker);
