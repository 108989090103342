import React, {FC, SyntheticEvent, useState} from 'react';
import {Box, IconButton, InputAdornment, TextField, Tooltip, Typography} from '@mui/material';
import Button from '@mui/lab/LoadingButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Casino from '@mui/icons-material/Casino';

interface PasswordStepProps {
  onPasswordSubmitted: (password: string) => void;
  isLoading: boolean;
  errorText: string | null;
}

const inputStyles = {
  input: {
    bgcolor: 'auth.main',
    padding: 5
  }
};

const containsSequentialChars = (str: string, minSequenceLength = 4): boolean => {
  if (str.length < minSequenceLength) return false;

  for (let i = 0; i <= str.length - minSequenceLength; i++) {
    let ascending = true;
    let descending = true;

    for (let j = i; j < i + minSequenceLength - 1; j++) {
      if (str.charCodeAt(j + 1) - str.charCodeAt(j) !== 1) {
        ascending = false;
      }

      if (str.charCodeAt(j) - str.charCodeAt(j + 1) !== 1) {
        descending = false;
      }
    }

    if (ascending || descending) return true;
  }

  return false;
};

const containsKeyboardSequence = (password: string, minSequenceLength = 4): boolean => {
  const keyboardRows = ['qwertyuiop', 'asdfghjkl', 'zxcvbnm', '1234567890'];
  const lowerPassword = password.toLowerCase();

  for (const row of keyboardRows) {
    for (let i = 0; i <= row.length - minSequenceLength; i++) {
      for (let len = minSequenceLength; len <= row.length - i; len++) {
        const seq = row.substring(i, i + len);
        const revSeq = seq.split('').reverse().join('');

        if (lowerPassword.includes(seq) || lowerPassword.includes(revSeq)) {
          return true;
        }
      }
    }
  }

  return false;
};

const validatePassword = (password: string): string => {
  const errors: string[] = [];

  if (password.length < 10) {
    errors.push('Пароль должен быть не менее 10 символов.');
  }

  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!?*&]).{10,}$/;

  if (!regex.test(password)) {
    errors.push('Пароль должен содержать заглавные и строчные буквы, цифры и символы !, ?, *, &.');
  }

  if (containsSequentialChars(password, 4) || containsKeyboardSequence(password, 4)) {
    errors.push(
      'Пароль не должен содержать последовательных символов (например, 123456, abcdef, qwerty).'
    );
  }

  return errors.join('\n');
};

const generateRandomPassword = (desiredLength = 12): string => {
  const lower = 'abcdefghijklmnopqrstuvwxyz';
  const upper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const digits = '0123456789';
  const specials = '!?*&';
  const allChars = lower + upper + digits + specials;

  const passwordArr = [
    lower[Math.floor(Math.random() * lower.length)],
    upper[Math.floor(Math.random() * upper.length)],
    digits[Math.floor(Math.random() * digits.length)],
    specials[Math.floor(Math.random() * specials.length)]
  ];

  for (let i = 4; i < desiredLength; i++) {
    passwordArr.push(allChars[Math.floor(Math.random() * allChars.length)]);
  }

  for (let i = passwordArr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [passwordArr[i], passwordArr[j]] = [passwordArr[j], passwordArr[i]];
  }

  const candidate = passwordArr.join('');

  if (validatePassword(candidate) !== '') {
    return generateRandomPassword(desiredLength);
  }

  return candidate;
};

const PasswordStep: FC<PasswordStepProps> = ({onPasswordSubmitted, isLoading, errorText}) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleSetPassword = (value: string) => {
    setPassword(value);
    const errorMsg = validatePassword(value);
    setPasswordError(errorMsg);

    if (confirmPassword && value !== confirmPassword) {
      setConfirmPasswordError('Пароли не совпадают.');
    } else {
      setConfirmPasswordError('');
    }
  };

  const handleSetConfirmPassword = (value: string) => {
    setConfirmPassword(value);

    if (password !== value) {
      setConfirmPasswordError('Пароли не совпадают.');
    } else {
      setConfirmPasswordError('');
    }
  };

  const handleGeneratePassword = () => {
    const generated = generateRandomPassword();
    setPassword(generated);
    setShowPassword(true);
    setConfirmPassword('');
    setPasswordError('');
    setConfirmPasswordError('');
  };

  const handleToggleShowPassword = () => {
    setShowPassword(prev => !prev);
  };

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      !passwordError &&
      !confirmPasswordError &&
      password &&
      confirmPassword &&
      password === confirmPassword
    ) {
      onPasswordSubmitted(password);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={14} paddingTop={8}>
        <TextField
          type={showPassword ? 'text' : 'password'}
          id="password"
          name="password"
          required
          fullWidth
          placeholder="Новый пароль"
          autoComplete="new-password"
          color="primary"
          sx={{
            minWidth: '400px',
            marginBottom: '2rem',
            ...inputStyles
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Box display="flex" alignItems="center">
                  <Tooltip title={showPassword ? 'Скрыть' : 'Показать'}>
                    <IconButton onClick={handleToggleShowPassword} size="small">
                      {showPassword ? (
                        <VisibilityOff fontSize="small" />
                      ) : (
                        <Visibility fontSize="small" />
                      )}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Сгенерировать случайный пароль">
                    <IconButton onClick={handleGeneratePassword} size="small">
                      <Casino fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </InputAdornment>
            )
          }}
          value={password}
          onChange={e => handleSetPassword(e.target.value)}
          error={!!passwordError}
          helperText={passwordError || '\u00A0'}
          FormHelperTextProps={{style: {whiteSpace: 'pre-line'}}}
        />
        <TextField
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          required
          fullWidth
          placeholder="Подтвердите новый пароль"
          autoComplete="new-password"
          color="primary"
          sx={{
            minWidth: '400px',
            ...inputStyles
          }}
          value={confirmPassword}
          onChange={e => handleSetConfirmPassword(e.target.value)}
          error={!!confirmPasswordError}
          helperText={confirmPasswordError || '\u00A0'}
        />
      </Box>
      <Button
        type="submit"
        variant="contained"
        loading={isLoading}
        loadingIndicator="Смена пароля…"
        fullWidth
      >
        Сменить пароль
      </Button>
      <Box textAlign="center" color="error.main">
        <Typography paddingTop={10} variant="body2">
          {errorText || '\u00A0'}
        </Typography>
      </Box>
    </form>
  );
};

export default PasswordStep;
