import {useEffect, useCallback} from 'react';

import {useLazyGetAllSourcesQuery} from '@/stores/api/dictionaries';
import {EFilterName, IOption} from '@/shared/models/tasksFilterModel';

type TUpdate = (key: EFilterName, options: IOption[]) => void;

export const useSourceFilters = (onUpdate: TUpdate) => {
  const [trigger, {data: sources}] = useLazyGetAllSourcesQuery();

  const handleLoadSources = useCallback(() => {
    if (!sources) {
      trigger({});
    }
  }, [sources, trigger]);

  useEffect(() => {
    if (sources) {
      onUpdate(EFilterName.Sources, sources);
    }
  }, [sources, onUpdate]);

  return {handleLoadSources};
};
