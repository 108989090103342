import {IFile} from '@/shared/models/fileModel';

export const downloadFile = async (file: IFile) => {
  if (!file) {
    console.warn('Файл отсутствует');
    return;
  }

  if (!file.publicUrl) {
    console.debug('Локальный файл', file);
    return;
  }

  try {
    const aElement = document.createElement('a');
    aElement.setAttribute('download', file.originalName);
    aElement.href = file.publicUrl;
    document.body.appendChild(aElement);
    aElement.click();
    document.body.removeChild(aElement);
  } catch (e) {
    console.warn('Не могу получить файл', e);
  }
};
