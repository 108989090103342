/**
 * Validation token by time
 * @returns boolean
 * @param expiredAt
 */
import axios from 'axios';

import {store} from '@/stores';
import {setAuthTokens} from '@/stores/AuthStore';

export const isTokenValid = (expiredAt: number, delta = 10) => {
  if (!expiredAt) {
    return false;
  }
  // отбрасываем дробную часть ~~
  const time = Date.now();
  const exp = new Date(expiredAt * 1000).getTime();

  // n миллисекунд до истечения времени жизни токена
  return exp - time >= delta * 1000;
};

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

let refreshTokenRequest: any | null = null;

export const refreshTokenCredentials = async (refreshToken: string) => {
  if (!refreshToken) {
    refreshTokenRequest = null;
    return null;
  }
  // to avoid race condition
  if (refreshTokenRequest === null) {
    const baseUrl = import.meta.env.APP_BASE_URL || '';
    refreshTokenRequest = axios.post(
      baseUrl + '/v1/auth/refresh-token',
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`
        }
      }
    );
  }

  const {data} = await refreshTokenRequest;

  const tokens = {
    accessToken: data.access_token,
    refreshToken: data.refresh_token
  };

  store.dispatch(setAuthTokens(tokens));

  // clean the value
  refreshTokenRequest = null;
  return tokens;
};

export const requestValidAccessToken = async () => {
  const state = store.getState();

  if (!state.authState.accessToken) {
    return null;
  }

  const accessToken = state.authState.accessToken;
  const refreshToken = state.authState.refreshToken;

  const {exp} = parseJwt(accessToken);

  if (!isTokenValid(exp)) {
    console.warn('Срок жизни токена истек');

    let data = null;
    try {
      // try refresh token event exp more than now
      data = await refreshTokenCredentials(refreshToken);
    } catch (error: any) {
      // relogin ?
      return null;
    }

    if (data) {
      return data?.accessToken;
    }
    return null;
  }

  return accessToken;
};
