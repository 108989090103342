import React, {useState} from 'react';
import {GridColDef, GridRenderCellParams, GridValueGetterParams} from '@mui/x-data-grid';
import {Box, Chip, Stack, Tooltip} from '@mui/material';
import {generatePath} from 'react-router';
import cn from 'classnames';
import {Link} from 'react-router-dom';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

import {formatDate} from '@/shared/utils/dateUtils';
import {RoutePaths} from '@/shared/constants/route';
import {useGetSubtasksByTaskIdMutation} from '@/stores/api/tasks-table/subtasks';
import {useAppSelector} from '@/stores/hooks';
import {tableSubtasksSelector} from '@/stores/TasksTableStore';
import {usePrepareDict} from '@/shared/hooks/usePrepareDict';
import {DeadlineChip} from '@/components/DeadlineChip';
import {checkCompanyIndividual} from '@/shared/utils/companyUtils';
import {companyIndividualTypeIdSelector} from '@/stores/TaskConditionsStore/TaskConditionsSelector';
import {ReactComponent as PlusIcon} from '@/assets/plus.svg';
import {ReactComponent as FilesIcon} from '@/assets/files.svg';
import {ReactComponent as CrownIcon} from '@/assets/crown.svg';

import {SubCell} from '../components/SubCell/index';
import s from '../TasksTable.module.css';

export const useColumns = () => {
  const dictionaries = usePrepareDict();
  const [openSubtasks, setOpenSubtasks] = useState<number[]>([]);
  const [getSubtasks] = useGetSubtasksByTaskIdMutation();
  const subtasksById = useAppSelector(tableSubtasksSelector);
  const companyIndividualType = useAppSelector(companyIndividualTypeIdSelector);

  const handleLoadSubtasks = (taskId: number) => {
    if (openSubtasks.includes(taskId)) {
      setOpenSubtasks(state => state.filter(o => o !== taskId));
    } else {
      setOpenSubtasks(state => [...state, taskId]);
      getSubtasks({taskId});
    }
  };

  const renderCell = (task: GridRenderCellParams, field?: string) => {
    const CellsComponents = {
      title: () => (
        <Link
          className={cn('lint-text', s.tableRow)}
          to={generatePath(RoutePaths.TaskPage, {
            taskId: `${task.id}`
          })}
        >
          {task.value}
        </Link>
      ),
      'company.nps': () => (
        <Stack direction="row" alignItems="center" spacing={1}>
          {task.row?.company?.dynamicNps > 0 && (
            <Tooltip title="Положительная динамика NPS">
              <span style={{color: 'green'}}>
                <ArrowUpwardIcon width={2} />
              </span>
            </Tooltip>
          )}
          {task.row?.company?.dynamicNps < 0 && (
            <Tooltip title="Отрицательная динамика NPS">
              <span style={{color: 'red'}}>
                <ArrowDownwardIcon />
              </span>
            </Tooltip>
          )}
          <span>{task.row?.company?.totalNps || ''}</span>
        </Stack>
      ),
      'company.name': () => (
        <span
          className={
            checkCompanyIndividual(
              companyIndividualType,
              task.row?.company?.companyTypeId,
              task.value
            )
              ? 'imp'
              : ''
          }
        >
          {task.row?.company?.isImportant && (
            <Tooltip title="Важный клиент">
              <span>
                <CrownIcon />{' '}
              </span>
            </Tooltip>
          )}
          {task.value}
        </span>
      ),
      deadlineAt: () => (
        <div className={s.deadlineCell}>
          <DeadlineChip
            deadlineDelta={task.row.deadlineDelta}
            deadlineAt={task.row.deadlineAt}
            createdAt={task.row.createdAt}
            doneAt={task.row.doneAt}
            componentType="chip"
          />
        </div>
      ),
      isHasFiles: () => (task.value ? <FilesIcon /> : <div>&nbsp;</div>),
      taskStatusName: () => {
        return task.value === 'Отложена' && task.row.delayAt ? (
          <Tooltip
            placement="right"
            title={
              <>
                {task.row.delayAt && `Отложена до ${formatDate(task.row.delayAt, 'dd.MM.yyyy')}`}
                <br />
                {task.row.delayTaskComment && task.row.delayTaskComment.content
                  ? `Причина: ${task.row.delayTaskComment.content}`
                  : 'Причина не указана'}
              </>
            }
          >
            <span>{task.value}</span>
          </Tooltip>
        ) : (
          <span>{task.value}</span>
        );
      },
      rate: () => {
        if (!task.value) {
          return <div>&nbsp;</div>;
        }

        const iconsMap = {
          2: {
            tooltip: 'Положительная оценка',
            icon: <ThumbUpIcon color="success" />
          },
          1: {
            tooltip: 'Отрицательная оценка',
            icon: <ThumbDownIcon color="error" />
          }
        };

        const iconData = iconsMap[task.value as 1 | 2];

        return <Tooltip title={iconData.tooltip}>{iconData.icon}</Tooltip>;
      }
    };
    return (
      <Box>
        <div className={s.tableRow}>
          {/* @ts-ignore */}
          {field && CellsComponents[field] ? CellsComponents[field]() : task.value}
        </div>
        <SubCell
          id={Number(task.id)}
          field={field}
          subtasks={subtasksById}
          isSelected={openSubtasks.includes(Number(task.id))}
          {...dictionaries}
        />
      </Box>
    );
  };

  return [
    {
      headerName: 'NPS',
      field: 'companyNps',
      width: 80,
      sortable: false,
      renderCell: params => {
        return renderCell(params, 'company.nps');
      }
    },
    {
      headerName: 'Наименование',
      field: 'companyName',
      width: 220,
      sortable: false,
      renderCell: params => {
        return renderCell(params, 'company.name');
      }
    },
    {
      headerName: 'Номер',
      field: 'ticket',
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const hasSubtasks = !!params.row.isHasSubtasks;
        return (
          <button
            style={{border: 0, background: 'none', position: 'relative'}}
            tabIndex={params.hasFocus ? 0 : -1}
            onClick={() => handleLoadSubtasks(Number(params.id))}
          >
            <div className={s.tableRow}>
              <span style={{position: 'absolute', display: 'block', width: '30px', left: -26}}>
                {hasSubtasks ? <PlusIcon width={18} /> : ''}
              </span>
              {params.value}
            </div>
            <SubCell
              id={Number(params.id)}
              field="ticket"
              subtasks={subtasksById}
              isSelected={openSubtasks.includes(Number(params.id))}
            />
          </button>
        );
      }
    },
    {
      headerName: '   ',
      field: 'userUnreadCommentsCount',
      width: 60,
      align: 'center',
      hideable: false,
      renderCell: ({value}) => {
        if (value) {
          return (
            <Tooltip title="Непрочитанные комментарии">
              <Chip color="warning" size="small" label={value} />
            </Tooltip>
          );
        }
        return <div>&nbsp;</div>;
      }
    },
    {
      headerName: 'Название',
      field: 'title',
      width: 262,
      renderCell: params => {
        return renderCell(params, 'title');
      }
    },
    {
      headerName: '   ',
      field: 'isHasFiles',
      width: '20',
      align: 'center',
      hideable: false,
      renderCell: params => {
        return renderCell(params, 'isHasFiles');
      }
    },
    {
      headerName: 'Статус',
      field: 'taskStatusName',
      width: 150,
      renderCell: params => {
        return renderCell(params, 'taskStatusName');
      }
    },
    {
      headerName: 'Оценка',
      field: 'rate',
      width: 80,
      renderCell: params => {
        return renderCell(params, 'rate');
      }
    },
    {
      headerName: 'Дата и время создания',
      field: 'createdAt',
      width: 150,
      valueGetter: (params: GridValueGetterParams) => {
        return `${formatDate(params.value, 'dd.MM.yyyy HH:mm')}`;
      },
      renderCell: params => {
        return renderCell(params, 'createdAt');
      }
    },
    {
      headerName: 'До выполнения осталось',
      field: 'deadlineAt',
      width: 150,
      align: 'right',
      // valueGetter: (params: GridValueGetterParams) => {
      //   return deadlineTime(params.value, params.row.doneAt);
      // },
      renderCell: params => {
        return renderCell(params, 'deadlineAt');
      }
    },
    {
      headerName: 'Дата и время выполнения',
      field: 'doneAt',
      width: 130,
      valueGetter: (params: GridValueGetterParams) => {
        if (!params.value) {
          return '-';
        }
        return `${formatDate(params.value, 'dd.MM.yyyy HH:mm')}`;
      },
      renderCell: params => {
        return renderCell(params, 'doneAt');
      }
    },
    {
      headerName: 'Тип',
      field: 'taskTypeName',
      width: 180,
      renderCell: params => {
        return renderCell(params, 'taskType.name');
      }
    },
    {
      headerName: 'Исполнитель',
      field: 'userName',
      width: 180,
      renderCell: params => {
        return renderCell(params, 'userName');
      }
    },
    {
      headerName: 'Отдел',
      field: 'departmentName',
      width: 180,
      renderCell: params => {
        return renderCell(params, 'department.name');
      }
    },
    {
      headerName: 'Источник',
      field: 'taskSourceName',
      width: 150,
      renderCell: params => {
        return renderCell(params, 'taskSource');
      }
    },
    {
      headerName: 'Автор',
      field: 'fromEmployeeName',
      width: 180,
      sortable: false,
      renderCell: params => {
        return renderCell(params, 'authorName');
      }
    },
    {
      headerName: 'Сотрудник клиента',
      field: 'employeeName',
      sortable: false,
      width: 180,
      renderCell: params => {
        return renderCell(params);
      }
    },
    {
      headerName: 'Направление',
      field: 'direction',
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => {
        return params.value ? 'Входящая' : 'Исходящая';
      },
      width: 120,
      renderCell: params => {
        return renderCell(params);
      }
    }
  ] as GridColDef[];
};
