import React, {FC, memo, useEffect, useMemo} from 'react';
import {createFilterOptions, FilterOptionsState} from '@mui/material';

import {ComboBox} from '@/components/UIKit';
import {ITask} from '@/shared/models/tasksDataModel';
import {IUser} from '@/shared/models';
import {useAppDispatch, useAppSelector} from '@/stores/hooks';
import {taskUserSelector, updateUser} from '@/stores/TaskSettingsStore';
import {OptionUser} from '@/components/UIKit/OptionUser';
import {useGetUsersQuery} from '@/stores/api/user';
import {formatUserName} from '@/shared/utils/fieldsUtils';
import {useGetDepartmentsQuery} from '@/stores/api/filtered-dictionaries.ts';
import {departmentNBUName} from '@/shared/constants/departmentConstants.ts';
import {userInfoSelector} from '@/stores/AuthStore/AuthStateSelector.ts';

interface IProps {
  task?: ITask;
  fieldLabel?: string;
  isLoading?: boolean;
  isSuccess?: boolean;
  isDisabled?: boolean;
  onChangeData: (params: Record<string, number>) => void;
}

const TaskUserBox: FC<IProps> = ({task, fieldLabel = '', isDisabled, onChangeData}) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(userInfoSelector);
  const editedUser = useAppSelector(taskUserSelector);

  /**
   * Для пользователей отдела НБУ в рамках своего подразделения разрешаем редактировать исполнителя
   */
  const departmentsIds = useMemo(() => {
    if (!currentUser?.userDepartments) {
      return;
    }
    return currentUser.userDepartments.map(({departmentId}) => departmentId);
  }, [currentUser?.userDepartments]);

  const {departmentNBU} = useGetDepartmentsQuery(
    {
      'filters[id]': 'in|' + departmentsIds
    },
    {
      skip: !departmentsIds,
      selectFromResult: ({data = []}) => ({
        departmentNBU: data.some(o => o.name === departmentNBUName)
      })
    }
  );

  const disabled =
    isDisabled &&
    !(departmentNBU && currentUser?.userUnits?.some(unit => unit.unitId === task?.unitId));

  const {data: users, isLoading: isUsersLoading} = useGetUsersQuery(
    {
      'filters[has_departments]': task?.departmentId,
      'filters[active]': 1,
      'filters[is_vacation]': 0
    },
    {
      skip: !task?.departmentId
    }
  );

  const handleChangeUser = (value?: IUser) => {
    dispatch(updateUser(value));
    if (value?.id) {
      onChangeData({
        user_id: value.id
      });
    }
  };

  useEffect(() => {
    if (task?.userId && task?.user) {
      dispatch(
        updateUser({
          ...task.user,
          name: formatUserName(task.user)
        })
      );
      return;
    }

    dispatch(updateUser(undefined));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, task?.userId]);

  useEffect(() => {
    return () => {
      dispatch(updateUser(undefined));
    };
  }, [dispatch]);

  const filter = createFilterOptions<IUser>({
    trim: true,
    stringify: option => option.name + option.email
  });

  return (
    <ComboBox<IUser>
      options={users || []}
      variantInput="standard"
      fieldLabel={fieldLabel}
      isLoading={isUsersLoading}
      sx={{
        minWidth: '400px'
      }}
      disableClearable
      value={editedUser || null}
      onChange={(_, newValue) => {
        // @ts-ignore
        handleChangeUser(newValue);
      }}
      placeholder="Выберите исполнителя"
      renderOption={(props, option: IUser) => {
        return <OptionUser {...props} key={option.id} option={option} />;
      }}
      filterOptions={(options: IUser[], params: FilterOptionsState<IUser>) => {
        return filter(options, params);
      }}
      disabled={disabled}
    />
  );
};

export default memo(TaskUserBox);
