import {useCallback, useEffect, useState} from 'react';

import {useLazyGetAllTypesQuery} from '@/stores/api/dictionaries';
import {EFilterName, IOption} from '@/shared/models/tasksFilterModel';
import {TPaginationModel} from '@/shared/models/tableModel.ts';
import {PAGE_SIZE} from '@/scenes/AllTasksPage/scenes/constants.ts';

type TUpdate = (key: EFilterName, options: IOption[]) => void;

export const useTaskTypesFilters = (
  onUpdate: TUpdate,
  isSubtaskPage?: boolean,
  isSupportTaskPage?: boolean
) => {
  const [paginationModel] = useState<TPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE
  });

  const [trigger, {data: types}] = useLazyGetAllTypesQuery();

  const handleLoadTaskTypes = useCallback(
    (search?: string) => {
      const query: Record<string, any> = {};

      if (isSubtaskPage) {
        query['filters[is_subtask]'] = 1;
      } else if (isSupportTaskPage) {
        query['filters[is_support_task]'] = 1;
      }

      if (search && search.trim().length > 0) {
        query.find = search;
      } else {
        query.page = {
          limit: paginationModel.pageSize,
          offset: paginationModel.page === 0 ? 0 : paginationModel.page * paginationModel.pageSize
        };
      }

      trigger(query);
    },
    [trigger, isSubtaskPage, isSupportTaskPage, paginationModel]
  );

  useEffect(() => {
    if (types) {
      onUpdate(EFilterName.Types, types);
    }
  }, [types, onUpdate]);

  return {handleLoadTaskTypes};
};
