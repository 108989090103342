import React, {FC, useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import {Link} from 'react-router-dom';
import {Chip, Divider, Paper, Skeleton, Stack, Typography} from '@mui/material';

import {useUpdateTaskMutation} from '@/stores/api/task-page/task-page';
import {ITaskUpdateDataRequest} from '@/shared/models/tasksDataModel';
import {useAppDispatch, useAppSelector} from '@/stores/hooks';
import {
  clearErrors,
  isIndividualCompanySelector,
  setCompanyError,
  setEmployeeError,
  setTypeError,
  taskEmployeeSelector,
  taskErrorsSelector,
  toCopyEmployeesSelector,
  updateDepartment
} from '@/stores/TaskSettingsStore';
import {RoutePaths} from '@/shared/constants/route';
import {ErrorTaskCondition} from '@/modals/confirmation/ErrorTaskCondition';
import {DeadlineChip} from '@/components/DeadlineChip/index';
import {TaskSettingsSection} from '@/components/TaskSettingsSection';
import {userInfoSelector} from '@/stores/AuthStore/AuthStateSelector';
import {errorTaskName, ETaskStatuses} from '@/shared/constants/taskStatuses';
import {CloseTaskConfirmation} from '@/modals/confirmation/CloseTaskConfirmation';
import {checkTaskClientMsg, checkTaskEmployeeMsg, checkTaskTypeMsg} from '@/shared/utils/taskUtils';
import {checkAllHeads} from '@/shared/utils/permissionUtils';
import {useTaskData} from '@/scenes/TaskPage/useTaskData';
import {useGetSubtasksByIdQuery} from '@/stores/api/task-page/subtasks';
import {useTaskStreaming} from '@/shared/hooks/useTaskStreaming.ts';

import {
  CreateSubtaskButton,
  DisplaySystemCommentsButton,
  DownloadAllButton,
  RecipientInput,
  RecipientInputCopy,
  TaskChat,
  TaskHeaderControls
} from './components/index';
import {TaskStatusButton} from './components/TaskStatusButton';
import {BitrixDeadlineChip} from './components/BitrixDeadlineChip';
import s from './TaskPage.module.css';

interface IProps {}

const TaskPage: FC<IProps> = () => {
  const {taskId} = useParams();
  const navigate = useNavigate();
  const currentUser = useAppSelector(userInfoSelector);
  const selectedEmployee = useAppSelector(taskEmployeeSelector);
  const [openConfirmation, setOpenConfirmation] = useState<ITaskUpdateDataRequest | null>(null);
  const [selectedDateDelay, setSelectedDateDelay] = useState<Date | null>(null);
  const [reasonDelay, setReasonDelay] = useState<string>('');
  const {data: task, isCanEdit, isLoading, isError, isSuccess} = useTaskData({taskId});

  const [submitUpdateTask, {isLoading: isUpdateLoading, isSuccess: isUpdateSuccess}] =
    useUpdateTaskMutation();

  const [isDisplaySystemComments, setIsDisplaySystemComments] = useState<boolean>(false);

  useTaskStreaming({taskId});

  const {data: subtasks} = useGetSubtasksByIdQuery(
    {
      taskId: task?.id
    },
    {
      skip: !task?.isHasSubtasks
    }
  );

  const dispatch = useAppDispatch();

  const source = task?.taskSourceId;
  const status = task?.taskStatusId;
  const delayAt = task?.delayAt;
  const taskType = task?.taskTypeId;

  const isErrorTask = task?.type?.name === errorTaskName;
  const isNewTask = status === ETaskStatuses.New;
  const isClosedTask = status === ETaskStatuses.Closed;

  const isIndividualCompany = useAppSelector(isIndividualCompanySelector);
  const errorList = useAppSelector(taskErrorsSelector);

  const handleChangeStatus = useCallback(
    (newStatus: number) => {
      dispatch(clearErrors());

      const params: ITaskUpdateDataRequest = {
        task_status_id: newStatus
      };

      // если нет исполнителя, подставим себя
      if (!task?.userId && currentUser?.id) {
        params['user_id'] = currentUser.id;
      }

      // при откладывании задачи отправляем кроме статуса
      // еще дату на которую отложили задачу
      if (newStatus === ETaskStatuses.Delay && selectedDateDelay !== null) {
        const delayAtFormatted = selectedDateDelay.toISOString();
        params['delay_at'] = delayAtFormatted;
        if (reasonDelay !== '') {
          params.delay_task_comment = {
            content: reasonDelay
          };
        }
      }
      // updated 22.08.23
      // перед тем как Взять в работу или Завершить,
      // нужно проставить тип
      const errorTypeMsg = checkTaskTypeMsg(taskType);

      const isIndividualProgressOrClosed =
        isIndividualCompany && [ETaskStatuses.Closed, ETaskStatuses.Progress].includes(newStatus);

      //если задача на физ.лице
      //перед тем как Взять в работу или Завершить
      //нужно выбрать Клиента
      const errorTaskClientMsg = checkTaskClientMsg(isIndividualProgressOrClosed);

      const taskEmployeeMsg = checkTaskEmployeeMsg(!selectedEmployee);
      if (errorTypeMsg || errorTaskClientMsg || taskEmployeeMsg) {
        dispatch(setTypeError(errorTypeMsg));
        dispatch(setCompanyError(errorTaskClientMsg));
        dispatch(setEmployeeError(taskEmployeeMsg));
        return;
      }

      // После нажатия на "Завершить" в карточке задачи надо
      // для руководителей выводить окно с чекбоксом
      // "Закрыть без отправки уведомления клиенту"
      // и кнопки "Закрыть" и "Отмена".
      const isBoss = checkAllHeads(currentUser);
      if (!task.chatId && (isBoss || task.delayAt) && newStatus === ETaskStatuses.Closed) {
        setOpenConfirmation(params);
        return;
      }

      handleUpdateTask(params);
      setReasonDelay('');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentUser,
      taskType,
      task?.userId,
      isIndividualCompany,
      selectedEmployee,
      selectedDateDelay,
      reasonDelay
    ]
  );

  const handleCloseConfirmation = () => {
    setOpenConfirmation(null);
  };

  const handleSubmitConfirmation = ({isRate}: {isRate: boolean}) => {
    const paramsWithRate = {
      ...openConfirmation,
      is_rate: isRate
    };
    handleUpdateTask(paramsWithRate);
    handleCloseConfirmation();
  };

  const handleUpdateTask = useCallback(
    async (editedParams: ITaskUpdateDataRequest) => {
      if (!taskId) {
        console.warn('taskId не найден');
        return;
      }

      const params = {
        taskId,
        data: editedParams,
        meta: {
          is_check_can_edit: true
        }
      };

      // при откладывании задачи отправляем кроме статуса
      // еще дату на которую отложили задачу
      // если есть причина, то отправляем и ее
      if (params.data['task_status_id'] === ETaskStatuses.Delay && selectedDateDelay) {
        params.data['delay_at'] = selectedDateDelay.toISOString();
        if (reasonDelay !== '') {
          params.data.delay_task_comment = {
            content: reasonDelay
          };
        }
      }

      // при смене исполнителя в рамках одного отдела
      // задача переходит из статуса "Принято в работу" на "Новая"
      if (params.data['user_id'] && !params.data['task_status_id']) {
        params.data['task_status_id'] = 1;
      }

      await submitUpdateTask(params);

      // При завершении задачи нужно возвращать пользователя в главную таблицу.
      if (params.data['task_status_id'] === ETaskStatuses.Closed) {
        navigate(RoutePaths.Main);
      }

      dispatch(updateDepartment());
    },
    [dispatch, navigate, submitUpdateTask, taskId, selectedDateDelay, reasonDelay]
  );

  const handleDisplaySystemCommentsButton = () => {
    setIsDisplaySystemComments(!isDisplaySystemComments);
  };

  useEffect(() => {
    dispatch(setTypeError(null));
  }, [dispatch, taskType]);

  const toCopyEmployees = useAppSelector(toCopyEmployeesSelector);

  const isSendAll = toCopyEmployees?.length > 0;
  const isDisabledInput = !isCanEdit || isNewTask;
  const isDisabledInputFile = !isCanEdit || isNewTask;
  const isDisabledSend = true;
  const isDisabledSendAll = !isSendAll || !isCanEdit || isNewTask;
  const isDisabledEmployee = !!task?.bitrixId || !isCanEdit;
  const isDisabledRecipientCopy = !!task?.chatId || isDisabledEmployee;

  const isHead = currentUser?.isHead;

  const isHeadUnit = currentUser?.isHeadUnit;

  return (
    <div className="scene">
      <header className={s.header}>
        <div className={s['header-section']}>
          <Stack direction="row" spacing={20} useFlexGap alignItems="center">
            <Typography variant="h1" sx={{whiteSpace: 'nowrap'}}>
              Задача №<span className="link">{task?.ticket}</span>
            </Typography>
            {!!subtasks?.length && (
              <Link to={`${RoutePaths.MainSubtasks}?task_id=${taskId}`} className="link">
                <Chip clickable label={`Подзадачи (${subtasks.length})`} variant="outlined" />
              </Link>
            )}
          </Stack>
        </div>
        <div className={s['header-center']}>
          <Stack direction="row" spacing={5} useFlexGap alignItems="center">
            <DeadlineChip
              deadlineDelta={task?.deadlineDelta}
              deadlineAt={task?.deadlineAt}
              createdAt={task?.createdAt}
              doneAt={task?.doneAt}
            />
            <BitrixDeadlineChip
              deadlineAt={task?.bitrixDeadlineAt}
              createdAt={task?.createdAt}
              doneAt={task?.doneAt}
            />
            <TaskStatusButton
              taskStatus={status}
              delayAt={delayAt}
              onChangeStatus={handleChangeStatus}
              selectedDateDelay={selectedDateDelay}
              setSelectedDateDelay={setSelectedDateDelay}
            />
          </Stack>
        </div>

        {taskId && (
          <div className={s['header-section']}>
            <TaskHeaderControls
              task={task}
              taskId={taskId}
              taskType={taskType}
              status={status}
              isErrorTask={isErrorTask}
              departmentId={task?.departmentId}
              isDisabled={isError || !isCanEdit}
              isUpdateLoading={isUpdateLoading}
              onChangeStatus={handleChangeStatus}
              selectedDateDelay={selectedDateDelay}
              setSelectedDateDelay={setSelectedDateDelay}
              reasonDelay={reasonDelay}
              setReasonDelay={setReasonDelay}
              isHead={isHead}
              isHeadUnit={isHeadUnit}
              taskTicket={task?.ticket}
            />
          </div>
        )}
      </header>
      <section className={s.section}>
        <Typography variant="h3" gutterBottom={false} className={s.title}>
          {isLoading && <Skeleton variant="rounded" width={210} height={24} />}
          {isSuccess && task?.title}
        </Typography>

        {isError && <span className="error">Ошибка. Задача не найдена</span>}
        {!isError && (
          <>
            <Stack spacing={8} direction="row" alignItems="flex-end" justifyContent="space-between">
              <TaskSettingsSection
                task={task}
                controls={[
                  'company',
                  'pin',
                  'deal',
                  'bitrix',
                  'employee',
                  'fromEmployee',
                  'sender',
                  'taskSource',
                  'direction'
                ]}
                onUpdateTask={handleUpdateTask}
                isLoading={isUpdateLoading}
                isSuccess={isUpdateSuccess}
                isDisabled={isDisabledEmployee}
              />

              {!isClosedTask && (
                <CreateSubtaskButton
                  parentTaskId={taskId ? Number(taskId) : undefined}
                  companyId={task?.companyId}
                  isDisabled={!isCanEdit}
                />
              )}
            </Stack>
            <Paper
              elevation={0}
              className={s.senderWrapper}
              sx={theme => ({
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column'
                }
              })}
            >
              <Stack className={s.senderInfo} direction="column" divider={<Divider />} spacing={4}>
                <RecipientInput />
                <RecipientInputCopy
                  isDisabled={isDisabledRecipientCopy}
                  onUpdateTask={handleUpdateTask}
                />
              </Stack>
            </Paper>
            <Stack
              direction="row"
              spacing={10}
              useFlexGap
              alignItems="center"
              justifyContent="space-between"
            >
              <TaskSettingsSection
                task={task}
                controls={['department', 'taskType', 'user']}
                onUpdateTask={handleUpdateTask}
                isDisabled={!isCanEdit}
              />

              <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                {task?.isHasFiles && taskId && <DownloadAllButton taskId={taskId} />}
                <DisplaySystemCommentsButton
                  onClick={handleDisplaySystemCommentsButton}
                  isDisplaySystemComments={isDisplaySystemComments}
                />
              </Stack>
            </Stack>
            {taskId && (
              <TaskChat
                taskId={taskId}
                chatId={task?.chatId}
                chatGroupId={task?.chatGroupId}
                chatTopicId={task?.chatTopicId}
                source={source}
                isLoading={isLoading}
                taskComments={task?.taskComments}
                status={status}
                isDisabled={isLoading || !isCanEdit}
                recipient={task?.employee}
                isDisabledSend={isDisabledSend}
                isDisabledSendAll={isDisabledSendAll}
                isDisabledInput={isDisabledInput}
                isDisabledInputFile={isDisabledInputFile}
                isDisplaySystemComments={isDisplaySystemComments}
                isSendAll={isSendAll}
              />
            )}
          </>
        )}
      </section>
      <CloseTaskConfirmation
        open={!!openConfirmation}
        onSubmit={handleSubmitConfirmation}
        onClose={handleCloseConfirmation}
      />

      <ErrorTaskCondition open={!!errorList?.length} onClose={() => dispatch(clearErrors())} />
    </div>
  );
};

export default TaskPage;
