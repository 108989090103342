import React, {FC, Fragment, useEffect} from 'react';
import {Stack} from '@mui/material';

import {ITaskUpdateDataRequest} from '@/shared/models/tasksDataModel';
import {useAppDispatch} from '@/stores/hooks';
import {MetaListItem} from '@/components/MetaListItem';
import {resetState} from '@/stores/TaskSettingsStore';
import {ISubtaskUpdateRequest} from '@/shared/models/subtaskModel';
import {formatUserName} from '@/shared/utils/fieldsUtils';
import {ETaskStatuses} from '@/shared/constants/taskStatuses';

import {TaskEmployeeBox} from './components/TaskEmployeeBox/index';
import {TaskCompanyBox} from './components/TaskCompanyBox/index';
import {TaskTypeBox} from './components/TaskTypeBox/index';
import {TaskUserBox} from './components/TaskUserBox/index';

interface IProps {
  task?: any; //ITask | ISubtask;
  isLoading?: boolean;
  isSuccess?: boolean;
  isDisabled?: boolean;
  controls: string[];
  onUpdateTask: (params: ITaskUpdateDataRequest | ISubtaskUpdateRequest) => void;
}

const TaskSettingsSection: FC<IProps> = ({
  task,
  controls,
  isLoading,
  isSuccess,
  isDisabled,
  onUpdateTask
}) => {
  const dispatch = useAppDispatch();
  const disabled = task?.taskStatusId === ETaskStatuses.Closed || isDisabled;

  const bitrixLinks: {[key: number]: string} = {
    1: 'https://peb.bitrix24.ru',
    2: 'https://bitrix.asia-import.ru',
    3: 'https://ms.metroservis.ru',
    4: 'https://liveplanet.bitrix24.ru',
    5: 'https://veterinar.bitrix24.ru',
    6: 'https://abend.bitrix24.ru'
  };

  const bitrixLink = bitrixLinks[task?.bitrixApiId] || bitrixLinks[1];

  const bitrixResponsibleIds: {[key: number]: string} = {
    1: '434',
    2: '786',
    3: '7021',
    4: '278',
    5: '3534',
    6: '234'
  };

  const bitrixResponsibleId = bitrixResponsibleIds[task?.bitrixApiId] || bitrixResponsibleIds[1];

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);

  const fieldsComponents = {
    company: <TaskCompanyBox isDisabled={disabled} task={task} />,
    noEditCompany: <MetaListItem field="Клиент">{task?.company?.name || '-'}</MetaListItem>,
    deal: task?.bitrixDealId && (
      <MetaListItem
        field="Сделка ID"
        title={task.bitrixDealId}
        linkTo={`${bitrixLink}/crm/deal/details/${task.bitrixDealId}/`}
      >
        {task.bitrixDealId || '-'}
      </MetaListItem>
    ),
    pin: task?.company?.bitrixId && (
      <MetaListItem
        field="Битрикс ID"
        title={task.company.bitrixId}
        linkTo={`https://peb.bitrix24.ru/crm/company/details/${task.company.bitrixId}/`}
      >
        {task.company.bitrixId || '-'}
      </MetaListItem>
    ),
    bitrix: task?.bitrixId && (
      <MetaListItem
        field="Задача ID"
        title={task.bitrixId}
        linkTo={`${bitrixLink}/company/personal/user/${bitrixResponsibleId}/tasks/task/view/${task.bitrixId}/`}
      >
        {task.bitrixId || '-'}
      </MetaListItem>
    ),
    employee: <TaskEmployeeBox isDisabled={disabled} onChangeData={onUpdateTask} task={task} />,
    fromEmployee: task?.fromEmployee?.email && (
      <MetaListItem field="Email отправителя" title={task.fromEmployee.email} isEmail>
        {task.fromEmployee.isSpam ? `${task.fromEmployee.email} (СПАМ)` : task.fromEmployee.email}
      </MetaListItem>
    ),
    sender: task?.fromEmployee?.email && (
      <MetaListItem field="Отправитель" title={task.fromEmployee.name}>
        {task.fromEmployee.isSpam ? `${task.fromEmployee.name} (СПАМ)` : task.fromEmployee.name}
      </MetaListItem>
    ),
    department: (
      <MetaListItem field="Отдел" name="department_id" title={task?.department?.id} isLink>
        {task?.department?.name || '-'}
      </MetaListItem>
    ),
    taskType: <TaskTypeBox task={task} onChangeData={onUpdateTask} isDisabled={disabled} />,
    user: (
      <TaskUserBox
        task={task}
        onChangeData={onUpdateTask}
        isLoading={isLoading}
        isSuccess={isSuccess}
        isDisabled={disabled}
        fieldLabel="Исполнитель задачи"
      />
    ),
    author: <MetaListItem field="Автор задачи">{formatUserName(task?.author)}</MetaListItem>,
    taskSource: (
      <MetaListItem field="Источник" name="task_source_id" title={task?.taskSource?.id}>
        {task?.taskSource?.name || '-'}
      </MetaListItem>
    ),
    direction: (
      <MetaListItem
        field="Направление"
        name="direction"
        title={task?.direction ? 'Входящая' : 'Исходящая'}
      >
        {task?.direction ? 'Входящая' : 'Исходящая'}
      </MetaListItem>
    )
  };

  if (!task) {
    return null;
  }

  return (
    <Stack
      direction="row"
      spacing={6}
      useFlexGap
      flexWrap="wrap"
      alignItems="center"
      sx={{marginBottom: '0.6rem'}}
    >
      {controls.map((item, index) => {
        if (item in fieldsComponents) {
          // @ts-ignore
          return <Fragment key={`${item}-${index}`}>{fieldsComponents[item]}</Fragment>;
        }
        return null;
      })}
    </Stack>
  );
};

export default TaskSettingsSection;
