import React, {FC, memo, useState} from 'react';
import {Button, CircularProgress} from '@mui/material';
import cn from 'classnames';

import {downloadFile} from '@/shared/utils/downloadFiles';
import {ReactComponent as DownloadIcon} from '@/assets/cloud-download.svg';
import {IFile} from '@/shared/models/fileModel';
import {useLazyGetZipFilesChatMessageQuery} from '@/stores/api/chats.ts';
import {useLazyGetZipFilesTaskCommentQuery} from '@/stores/api/task-page/task-comment.ts';
import {useLazyGetZipFilesSubtaskCommentQuery} from '@/stores/api/subtask-page/subtask-comment.ts';
import {useLazyGetZipFilesSupportTaskCommentQuery} from '@/stores/api/support-task-page/support-task-comment.ts';

import s from './ChatFilesDownloadButton.module.css';

interface IProps {
  className?: string;
  messageId: number;
  chatId?: string;
  taskId?: number;
  subtaskId?: number;
  supportTaskId?: number;
}

const ChatFilesDownloadButton: FC<IProps> = ({
  className,
  messageId,
  chatId,
  taskId,
  subtaskId,
  supportTaskId
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [triggerGetZipFilesTaskComment] = useLazyGetZipFilesTaskCommentQuery();
  const [triggerGetZipFilesSubtaskComment] = useLazyGetZipFilesSubtaskCommentQuery();
  const [triggerGetZipFilesSupportTaskComment] = useLazyGetZipFilesSupportTaskCommentQuery();
  const [triggerGetZipFilesChatMessage] = useLazyGetZipFilesChatMessageQuery();

  const handleGetFiles = async () => {
    setIsLoading(true);

    try {
      const endpoints = [
        {
          condition: supportTaskId,
          trigger: () =>
            triggerGetZipFilesSupportTaskComment({
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              taskId: supportTaskId!.toString(),
              taskCommentId: messageId.toString()
            })
        },
        {
          condition: subtaskId,
          trigger: () =>
            triggerGetZipFilesSubtaskComment({
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              taskId: subtaskId!.toString(),
              taskCommentId: messageId.toString()
            })
        },
        {
          condition: taskId,
          trigger: () =>
            triggerGetZipFilesTaskComment({
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              taskId: taskId!.toString(),
              taskCommentId: messageId.toString()
            })
        },
        {
          condition: chatId,
          trigger: () =>
            triggerGetZipFilesChatMessage({
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              chatId: chatId!,
              chatMessageId: messageId.toString()
            })
        }
      ];

      const endpoint = endpoints.find(e => e.condition);

      if (endpoint) {
        const response: IFile = await endpoint.trigger().unwrap();
        await downloadFile(response);
      }
    } catch (error) {
      console.error('Ошибка при загрузке файлов:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      className={cn(s.button, className)}
      startIcon={isLoading ? <CircularProgress color="inherit" size={24} /> : <DownloadIcon />}
      variant="text"
      onClick={handleGetFiles}
      disabled={isLoading}
    >
      {isLoading ? 'Скачивание архива...' : 'Скачать файлы'}
    </Button>
  );
};

export default memo(ChatFilesDownloadButton);
