import React, {FC, memo, useEffect, useMemo} from 'react';
import cn from 'classnames';

import {ComboBox} from '@/components/UIKit';
import {IOption} from '@/shared/models/tasksFilterModel';
import {ITask} from '@/shared/models/tasksDataModel';
import {useAppDispatch, useAppSelector} from '@/stores/hooks';
import {
  setDepartmentError,
  taskCompanySelector,
  taskUnitSelector,
  updateDepartment,
  updateUnit
} from '@/stores/TaskEditStore';
import {ISubtask} from '@/shared/models/subtaskModel';
import {usePrepareDict} from '@/shared/hooks/usePrepareDict';
import {IUnit} from '@/shared/models/dictionaryModel';
import {useGetUnitsQuery} from '@/stores/api/filtered-dictionaries';
import {ISupportTask} from '@/shared/models/supportTaskModel';
import {checkTaskDepartmentMsg} from '@/shared/utils/taskUtils';
import {useGetCompanyByIdQuery} from '@/stores/api/companies';

interface IProps {
  task?: ITask | ISubtask | ISupportTask;
  isDisabled?: boolean;
  isLoading?: boolean;
  onChangeData: (params: Record<string, number>) => void;
}

const TaskUnitBox: FC<IProps> = ({task, isDisabled, isLoading, onChangeData}) => {
  const dispatch = useAppDispatch();
  const taskUnit = useAppSelector(taskUnitSelector);
  const editedCompany = useAppSelector(taskCompanySelector);
  const {unitsIds: unitsIds} = usePrepareDict();
  const [isValueChanged, setIsValueChanged] = React.useState(false);

  const {responsibleUnitIds} = useGetCompanyByIdQuery(
    {
      companyId: editedCompany?.id
    },
    {
      skip: !editedCompany?.id,
      selectFromResult: ({data}) => ({
        responsibleUnitIds: data?.companyResponsibles?.length
          ? data.companyResponsibles
              .filter(({unitId}) => unitId)
              .map(({unitId}) => unitId)
              .join(',')
          : ''
      })
    }
  );

  const unitFilters = {
    is_without_unknown: 1
  };

  const {units, isUnitsLoading} = useGetUnitsQuery(
    {
      filters: responsibleUnitIds ? {id: 'in|' + responsibleUnitIds, ...unitFilters} : unitFilters
    },
    {
      skip: !responsibleUnitIds,
      selectFromResult: ({data, isLoading}) => ({
        units: data ? data.filter(o => o.id !== taskUnit?.id) : [],
        isUnitsLoading: isLoading
      })
    }
  );

  const initUnit = useMemo(() => {
    /**
     * При инициализации подставляем подразделение из полного списка
     * в отфильтрованной версии может не быть выбранного
     */
    if (!unitsIds || !task?.unitId) {
      return;
    }

    return unitsIds[task.unitId];
  }, [task?.unitId, unitsIds]);

  useEffect(() => {
    if (initUnit) {
      dispatch(updateUnit(initUnit));
    }
  }, [dispatch, initUnit]);

  const handleChangeUnit = (value?: IUnit) => {
    dispatch(updateUnit(value));
    dispatch(updateDepartment());
    dispatch(setDepartmentError(checkTaskDepartmentMsg(true)));

    if (value) {
      value?.id !== task?.unitId ? setIsValueChanged(true) : setIsValueChanged(false);

      onChangeData({
        unit_id: Number(value.id)
      });
    }
  };

  return (
    <ComboBox<IOption>
      options={units || []}
      variantInput="standard"
      fieldLabel="Подразделение"
      loading={isLoading || isUnitsLoading}
      sx={{
        minWidth: '400px'
      }}
      disableClearable
      value={taskUnit || null}
      // @ts-ignore
      onChange={(_, newValue?: IUnit) => {
        handleChangeUnit(newValue);
      }}
      placeholder="Выберите подразделение"
      disabled={isDisabled}
      inputClassName={cn({'combo-box-changed': isValueChanged})}
    />
  );
};

export default memo(TaskUnitBox);
