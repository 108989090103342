import React, {FC} from 'react';
import {Stack, Typography} from '@mui/material';

interface IProps {}

const NpsPage: FC<IProps> = () => {
  return (
    <div className="scene" style={{display: 'flex'}}>
      <header className="scene-header">
        <Typography variant="h1" gutterBottom sx={{marginBottom: '2rem'}}>
          NPS
        </Typography>
      </header>
      <Stack direction="column" spacing={4} sx={{flex: 1}}>
        <iframe
          title={'Статистика NPS по клиенту'}
          src="https://redash.infra.peb.guru/embed/query/162/visualization/174?hide_link&api_key=9DhlmWfUkBdS7rGehVQYpi3TEH4Fusy4qVHfSS4f"
          style={{minWidth: '100%', minHeight: '100%'}}
          scrolling="no"
        ></iframe>
        <iframe
          title={'Статистика NPS по дате опроса'}
          src="https://redash.infra.peb.guru/embed/query/164/visualization/176?hide_link&api_key=9DhlmWfUkBdS7rGehVQYpi3TEH4Fusy4qVHfSS4f"
          style={{minWidth: '100%', minHeight: '100%'}}
          scrolling="no"
        ></iframe>
        <iframe
          title={'Статистика задач NPS по клиенту'}
          src="https://redash.infra.peb.guru/embed/query/166/visualization/178?hide_link&api_key=9DhlmWfUkBdS7rGehVQYpi3TEH4Fusy4qVHfSS4f"
          style={{minWidth: '100%', minHeight: '100%'}}
          scrolling="no"
        ></iframe>
        <iframe
          title={'Статистика задач NPS по дате опроса'}
          src="https://redash.infra.peb.guru/embed/query/165/visualization/177?hide_link&api_key=9DhlmWfUkBdS7rGehVQYpi3TEH4Fusy4qVHfSS4f"
          style={{minWidth: '100%', minHeight: '100%'}}
          scrolling="no"
        ></iframe>
      </Stack>
    </div>
  );
};

export default NpsPage;
