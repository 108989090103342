import {api} from '@/stores/api/index';
import {
  ICommonArgs,
  ICommonResponse,
  IMeta,
  IMetaResponse,
  IPagination
} from '@/shared/models/commonModel';
import {ICompany, ICompanyResponse} from '@/shared/models/companyModel';
import {formatKeys} from '@/shared/utils/responseUtils';

interface IQueryArg extends ICommonArgs {
  'filters[company_type_id]'?: number;
  'filters[without_inner_support_companies]'?: number;
}

export const apiWithCompanies = api.injectEndpoints({
  endpoints: builder => ({
    getCompanies: builder.query<IPagination<ICompanyResponse[]>, IQueryArg>({
      query: params => ({
        url: '/v1/companies/',
        method: 'get',
        params: {
          ...params,
          include: [params.include, ''].filter(Boolean).join(',') || '',
          fields: [params.fields, 'id,name,company_type_id,unit_id'].filter(Boolean).join(',')
        }
      }),
      transformResponse: (result: ICommonResponse<ICompanyResponse[]>): IPagination<ICompany[]> => {
        return {
          meta: formatKeys<IMetaResponse, IMeta>(result.meta),
          data: result.data.map(item => {
            return {
              ...formatKeys<ICompanyResponse, ICompany>(item)
            };
          })
        };
      },
      // @ts-ignore
      providesTags: result => {
        return result && result?.data
          ? [
              ...result.data.map(({id}) => ({type: 'COMPANIES', id})),
              {type: 'COMPANIES', id: 'LIST'}
            ]
          : [{type: 'COMPANIES', id: 'LIST'}];
      }
    }),
    fetchCompanies: builder.mutation<IPagination<ICompanyResponse[]>, IQueryArg>({
      query: params => ({
        url: '/v1/companies/',
        method: 'get',
        params: {
          ...params,
          include: [params.include, ''].filter(Boolean).join(',') || '',
          fields: [params.fields, 'id,name,company_type_id,unit_id'].filter(Boolean).join(',')
        }
      }),
      transformResponse: (result: ICommonResponse<ICompanyResponse[]>): IPagination<ICompany[]> => {
        return {
          meta: formatKeys<IMetaResponse, IMeta>(result.meta),
          data: result.data.map(item => {
            return {
              ...formatKeys<ICompanyResponse, ICompany>(item)
            };
          })
        };
      }
    }),
    getCompanyById: builder.query<ICompany, {companyId?: number}>({
      query: ({companyId}: {companyId: number}) => ({
        url: `/v1/companies/${companyId}`,
        method: 'get',
        params: {
          include: 'company_responsibles',
          fields: 'id,name,bitrix_id,unit_id'
        }
      }),

      transformResponse: (result: {data: ICompanyResponse}): ICompany | Promise<ICompany> => {
        return formatKeys<ICompanyResponse, ICompany>(result.data);
      },

      providesTags: (_result, _error, {companyId}) => {
        return [{type: 'COMPANY', id: companyId}];
      }
    })
  }),
  overrideExisting: true
});

export const {
  useGetCompaniesQuery,
  useLazyGetCompaniesQuery,
  useFetchCompaniesMutation,
  useGetCompanyByIdQuery
} = apiWithCompanies;
