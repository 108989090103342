import {api} from '@/stores/api/index';
import {IUser, IUserResponse} from '@/shared/models';
import {formatKeys} from '@/shared/utils/responseUtils';
import {ICommonArgs, ICommonResponse} from '@/shared/models/commonModel';
import {formatUserName} from '@/shared/utils/fieldsUtils';

interface IQueryArg extends ICommonArgs {}

export const apiWithUser = api.injectEndpoints({
  endpoints: builder => ({
    userInfo: builder.query<IUser, void>({
      query: () => ({
        url: '/v1/users/me',
        method: 'post'
      }),
      transformResponse: (result: {data: IUserResponse}): IUser => {
        return formatKeys<IUserResponse, IUser>(result.data);
      }
    }),
    getUsers: builder.query<IUser[], IQueryArg>({
      query: params => ({
        url: '/v1/users/',
        method: 'get',
        params
      }),
      // @ts-ignore
      transformResponse: (result: ICommonResponse<IUserResponse[]>): IUser[] => {
        if (!result?.data?.length) {
          return [];
        }
        return result.data.map(item => {
          const formatedUser = formatKeys<IUserResponse, IUser>(item);
          return {
            ...formatedUser,
            name: formatUserName(formatedUser)
          };
        });
      },
      // @ts-ignore
      providesTags: result => {
        return result
          ? [
              // @ts-ignore
              ...result.map(({id}) => ({type: 'USERS', id})),
              {type: 'USERS', id: 'LIST'}
            ]
          : [{type: 'USERS', id: 'LIST'}];
      }
    })
  }),
  overrideExisting: true
});

export const {useUserInfoQuery, useGetUsersQuery, useLazyGetUsersQuery} = apiWithUser;

// export const userInfoQueryState = apiWithUser.endpoints.userInfo.useQueryState;
export const usersQueryState = apiWithUser.endpoints.getUsers.useQueryState;
