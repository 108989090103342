import {PayloadAction} from '@reduxjs/toolkit';

import {
  EFilterName,
  IFilterState,
  IOption,
  TDateFilterRange,
  TFilterValues,
  TTempFilterValues
} from '@/shared/models/tasksFilterModel';
import {RootState} from '@/stores';
import {IPagination} from '@/shared/models/commonModel';
import {formatUserName} from '@/shared/utils/fieldsUtils';
import {IUser} from '@/shared/models';
import {individualCompanies} from '@/shared/constants/companyConstants';
import {withoutUser, withoutUserName} from '@/shared/constants/userConstants.ts';

export const initialState: IFilterState = {
  isUpdated: false,
  isFetched: false, //
  tempFilterValues: {} as TTempFilterValues, // main filters, temporary state
  filterValues: {} as TFilterValues, // main filters works by submit button, control table
  filterListDates: {} as TDateFilterRange,
  filterList: {
    [EFilterName.Units]: {
      fieldName: EFilterName.Units,
      fieldLabel: 'Подразделение',
      options: [],
      isLoading: false,
      isMultiply: true,
      selectAll: true,
      minWidth: 210
    },
    [EFilterName.Departments]: {
      fieldName: EFilterName.Departments,
      fieldLabel: 'Отдел',
      options: [],
      isLoading: false,
      isMultiply: true,
      selectAll: true,
      minWidth: 210
    },
    [EFilterName.Head]: {
      fieldName: EFilterName.Head,
      fieldLabel: 'Руководитель',
      options: [],
      isLoading: false,
      selectAll: true
    },
    [EFilterName.Users]: {
      fieldName: EFilterName.Users,
      fieldLabel: 'Исполнитель',
      options: [
        {
          id: withoutUser,
          name: withoutUserName
        }
      ],
      isLoading: false,
      isMultiply: true,
      selectAll: true,
      minWidth: 260
    },
    [EFilterName.Statuses]: {
      fieldName: EFilterName.Statuses,
      fieldLabel: 'Статус',
      options: [],
      isLoading: false,
      selectAll: true,
      isMultiply: true,
      minWidth: 210
    },
    [EFilterName.Types]: {
      fieldName: EFilterName.Types,
      fieldLabel: 'Тип задачи',
      options: [],
      isLoading: false,
      isMultiply: true,
      selectAll: true,
      minWidth: 210
    },
    [EFilterName.Sources]: {
      fieldName: EFilterName.Sources,
      fieldLabel: 'Источник',
      options: [],
      isLoading: false,
      selectAll: true
    },
    [EFilterName.Direction]: {
      fieldName: EFilterName.Direction,
      fieldLabel: 'Направление',
      options: [
        {
          id: 1,
          name: 'Входящая'
        },
        {
          id: -1,
          name: 'Исходящая'
        }
      ],
      isLoading: false,
      selectAll: true
    },
    [EFilterName.CompanyId]: {
      fieldName: EFilterName.CompanyId,
      fieldLabel: 'Название компании',
      options: [
        {
          id: individualCompanies,
          name: 'Физ лица',
          companyTypeId: 2
          // filters[physic_companies]=1
        },
        {
          id: 1,
          name: 'Клиент не определён'
        }
      ],
      isLoading: false,
      isMultiply: true,
      selectAll: true,
      minWidth: 260
    },
    [EFilterName.Rate]: {
      fieldName: EFilterName.Rate,
      fieldLabel: 'Оценка',
      options: [
        {
          id: -1,
          name: 'Без оценки'
        },
        {
          id: 1,
          name: 'Отрицательная'
        },
        {
          id: 2,
          name: 'Положительная'
        }
      ],
      isLoading: false,
      selectAll: true
    },
    [EFilterName.CreatedAt]: {
      fieldName: EFilterName.CreatedAt,
      fieldLabel: 'Дата создания',
      options: [],
      isLoading: false,
      type: 'date'
    },
    [EFilterName.DoneAt]: {
      fieldName: EFilterName.DoneAt,
      fieldLabel: 'Дата выполнения (факт)',
      options: [],
      isLoading: false,
      type: 'date'
    },
    [EFilterName.DeadlineAt]: {
      fieldName: EFilterName.DeadlineAt,
      fieldLabel: 'Дата выполнения (план)',
      options: [],
      isLoading: false,
      type: 'date'
    }
  }
};

export const updateFiltersFromDictApi = (state: RootState, action: PayloadAction<IOption[]>) => {
  return (fieldName: EFilterName) => {
    if (action.payload && state.filterList[fieldName]) {
      state.filterList[fieldName] = {
        ...state.filterList[fieldName],
        options: [...action.payload]
      };

      return state;
    }

    return state;
  };
};

export const updateFiltersFromUserApi = (
  state: RootState,
  action: PayloadAction<IPagination<IUser[]>>
) => {
  return (fieldName: EFilterName) => {
    if (action.payload?.data && state.filterList[fieldName]) {
      const usersOptions = action.payload.data.map(user => {
        return {
          id: user.id,
          name: formatUserName(user)
        };
      });
      state.filterList[fieldName] = {
        ...state.filterList[fieldName],
        options: [...usersOptions]
      };

      return state;
    }

    return initialState;
  };
};
