import {useEffect, useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';

import {usePrepareDict} from '@/shared/hooks/usePrepareDict';
import {useGetSubtasksTableQuery} from '@/stores/api/subtasks-table/subtasks-table';
import {ITableHookProps, TPaginationModel} from '@/shared/models/tableModel';
import {useAppSelector} from '@/stores/hooks';
import {userInfoSelector} from '@/stores/AuthStore/AuthStateSelector';
import {counterSelector, searchSelector} from '@/stores/SearchStateStore/SearchStateSelector.ts';
import {getCounterFilters} from '@/shared/utils/countersUtils';
import {prepareUrlParams} from '@/shared/utils/urlParamsUtils.ts';
import {EAdditionalValues, EFilterName} from '@/shared/models/tasksFilterModel.ts';
import {subtasksFilterValuesSelector} from '@/stores/SubtasksFilterStore/SubtasksFilterSelector.ts';
import {prepareSortFunc} from '@/shared/utils/sortUtils.ts';
import {withoutUser} from '@/shared/constants/userConstants.ts';

interface QueryParams {
  page: {
    limit: number;
    offset: number;
  };
  sort?: string;
  'filters[department_id]'?: any;
  'filters[user_id]'?: any;
  'filters[author_id]'?: any;
  'filters[task_status_id]'?: any;
  'filters[task_source_id]'?: any;
  'filters[task_type_id]'?: any;
  'filters[unit_id]'?: any;
  'filters[company_id]'?: any;
  'counters[user_unread_comments]'?: number;
  [key: string]: any;
}

export const useGetSubtasksData = ({
  paginationModel,
  setPaginationModel,
  sortModel
}: ITableHookProps) => {
  const filterValues = useAppSelector(subtasksFilterValuesSelector);
  const [urlParams] = useSearchParams();
  const currentUser = useAppSelector(userInfoSelector);
  const {isReady} = usePrepareDict();
  const searchState = useAppSelector(searchSelector);
  const counterState = useAppSelector(counterSelector);

  const parentTaskParam = useMemo(() => {
    return urlParams.get('task_id') || '';
  }, [urlParams]);

  const sort = useMemo(() => {
    if (!sortModel.length) {
      return 'active_subtasks';
    }
    return prepareSortFunc(sortModel);
  }, [sortModel]);

  const showOnlyMyTasks = useMemo(() => {
    let i = 0;
    if (searchState !== '') {
      return false;
    }
    for (const entry of Object.entries<any>(filterValues)) {
      const [key, val] = entry;

      // @ts-ignore
      if (![EAdditionalValues.Page, EAdditionalValues.Sort].includes(key) && val) {
        i++;
      }
    }

    return i === 0;
  }, [filterValues, searchState]);

  const query = useMemo(() => {
    const page = {
      limit: paginationModel.pageSize,
      offset: paginationModel.page === 0 ? 0 : paginationModel.page * paginationModel.pageSize
    };

    const params: QueryParams = {
      page,
      sort,
      'filters[head]': prepareUrlParams(filterValues[EFilterName.Head]),
      'filters[user_id]': prepareUrlParams(filterValues[EFilterName.Users]),
      'filters[author_id]': prepareUrlParams(filterValues[EFilterName.Author]),
      'filters[task_status_id]': prepareUrlParams(filterValues[EFilterName.Statuses]),
      'filters[task_source_id]': prepareUrlParams(filterValues[EFilterName.Sources]),
      'filters[task_type_id]': prepareUrlParams(filterValues[EFilterName.Types]),
      'filters[company_id]': prepareUrlParams(filterValues[EFilterName.CompanyId]),

      'filters[my_subtasks]': showOnlyMyTasks ? 1 : undefined,
      'counters[unread_comments]': currentUser?.id
    };

    const allCountersFilters = getCounterFilters(true);
    const counterFilter = allCountersFilters[counterState as keyof typeof allCountersFilters];

    if (counterState) {
      // @ts-ignore
      params[counterFilter] =
        counterFilter === 'filters[has_unread_comments]' ? `${currentUser?.id}` : 1;
    }

    if (filterValues[EFilterName.CreatedAt]) {
      params['filters[created_at]'] = filterValues[EFilterName.CreatedAt];
    }

    if (filterValues[EFilterName.DoneAt]) {
      params['filters[done_at]'] = filterValues[EFilterName.DoneAt];
    }

    if (filterValues[EFilterName.DeadlineAt]) {
      params['filters[deadline_at]'] = filterValues[EFilterName.DeadlineAt];
    }

    if (searchState) {
      // @ts-ignore
      params.find = searchState;
    }

    if (parentTaskParam) {
      // @ts-ignore
      params['filters[task_id]'] = parentTaskParam;
    }

    if (filterValues[EFilterName.Users] === withoutUser) {
      params['filters[user_id]'] = 'is|null';
    }

    return params;
  }, [
    paginationModel.pageSize,
    paginationModel.page,
    sort,
    filterValues,
    showOnlyMyTasks,
    currentUser?.id,
    counterState,
    searchState,
    parentTaskParam
  ]);

  const {data, isLoading} = useGetSubtasksTableQuery(query, {
    skip: !isReady,
    refetchOnMountOrArgChange: true,
    pollingInterval: 40000
  });

  const urlPage: number = urlParams.get('page') ? parseInt(urlParams.get('page') || '0', 10) : 0;

  useEffect(() => {
    setPaginationModel((state: TPaginationModel) => {
      if (state.page === urlPage) {
        return state;
      }
      return {
        pageSize: state.pageSize,
        page: urlPage
      };
    });
  }, [setPaginationModel, urlPage]);

  return {
    data,
    isLoading
  };
};
