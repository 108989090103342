import React, {FC, useEffect, useState} from 'react';
import Lottie from 'lottie-react';
import pako from 'pako';
import {CircularProgress} from '@mui/material';

interface IProps {
  stickerUrl: string;
  isAnimated: boolean;
}

const TelegramSticker: FC<IProps> = ({stickerUrl, isAnimated}) => {
  const [animationData, setAnimationData] = useState<any>(null);

  useEffect(() => {
    if (isAnimated) {
      fetch(stickerUrl, {mode: 'cors'})
        .then(response => response.arrayBuffer())
        .then(arrayBuffer => {
          try {
            const decompressed = pako.ungzip(new Uint8Array(arrayBuffer), {to: 'string'});
            const jsonData = JSON.parse(decompressed);
            setAnimationData(jsonData);
          } catch (error) {
            console.error('Ошибка при распаковке или парсинге анимированного стикера:', error);
          }
        })
        .catch(error => console.error('Ошибка загрузки анимированного стикера:', error));
    }
  }, [stickerUrl, isAnimated]);

  if (isAnimated) {
    return (
      <div style={{width: 128, height: 128}}>
        {animationData ? (
          <Lottie animationData={animationData} loop={true} />
        ) : (
          <CircularProgress color="inherit" size={24} />
        )}
      </div>
    );
  }

  return <img src={stickerUrl} alt="sticker" width="128" height="128" />;
};

export default TelegramSticker;
