import React, {FC} from 'react';
import cn from 'classnames';
import {Chip, CircularProgress, Divider, Stack, Tooltip, Typography} from '@mui/material';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import linkifyHtml from 'linkify-html';

import {IMessage} from '@/shared/models/messageModel';
import {formatDate} from '@/shared/utils/dateUtils';
import {IFile} from '@/shared/models/fileModel';
import {ChatFilesDownloadButton} from '@/components/Chat/components/ChatFilesDownloadButton';
import {HtmlContent} from '@/components/HtmlContent';
import {IChatMessage} from '@/shared/models/chatModel.ts';
import {messageUserFullName} from '@/shared/utils/chatUtils.ts';

import {ChatFile} from '../ChatFile';

import {ReactComponent as DoneIcon} from './assets/check.svg';
import {ReactComponent as DoneAllIcon} from './assets/double-check.svg';
import s from './ChatMessage.module.css';

interface IProps {
  message: IMessage | IChatMessage | any;
  onGetFile: (file: IFile) => void;
  chatId?: string;
  chatGroupId?: number;
  chatTopicId?: number;
  isDisplaySystemComments: boolean;
  isTaskChat?: boolean;
  isMessageChat?: boolean;
  isTelegramChat?: boolean;
  onScrollToMessage?: (messageId: number) => void;
  onContextMenu?: (event: React.MouseEvent, message: IChatMessage) => void;
}

const ChatMessage: FC<IProps> = ({
  message,
  onGetFile,
  chatId,
  chatGroupId,
  chatTopicId,
  isDisplaySystemComments,
  isTaskChat,
  isMessageChat,
  isTelegramChat,
  onScrollToMessage,
  onContextMenu
}) => {
  const {
    id,
    taskId,
    subtaskId,
    supportTaskId,
    createdAt,
    isRead,
    isSending,
    isEdited,
    isDeleted,
    content,
    direction,
    isChangeDepartment,
    isDelay,
    isSpectator,
    isInner,
    chatMessageId,
    taskIds,
    chatMessageReply,
    userId,
    telegramId
  } = message;

  if (message.isSystem && !isMessageChat) {
    if (!isDisplaySystemComments) {
      return null;
    }

    return (
      <div className={s.messagesSystem}>
        <Typography variant="body2" className={s.messagesSystemText}>
          <span className={s.messagesItemTime}>
            {formatDate(createdAt, 'dd MMM yyyy в HH:mm')}&nbsp;
          </span>
          {messageUserFullName && <span>{messageUserFullName(message)}:&nbsp;</span>}
          {content}
        </Typography>
      </div>
    );
  }

  const processedContent = content
    ? linkifyHtml(content, {
        target: '_blank',
        rel: 'noopener noreferrer'
      })
    : content;

  const hasStatusChip =
    !isSending && ((isTelegramChat && userId) || (isEdited && !isDeleted) || isDeleted);

  return (
    <div className={cn(s.messagesItem, !direction && s.messagesItemSent)}>
      <div className={s.messagesItemInfo}>
        {isChangeDepartment && 'Комментарий при передаче '}
        {isDelay && 'Комментарий при переносе'}
        {isSpectator && 'Комментарий для наблюдателя'}
        {isInner && 'Внутренний комментарий'}
        <br />
        {taskIds && (
          <div key={taskIds.join(',')}>
            <span className={s.messageHeaderLabel}>Задача №</span>
            {taskIds.map((taskId: string, index: number) => (
              <React.Fragment key={taskId}>
                <a
                  href={`/task/${taskId}`}
                  target="_blank"
                  rel="noreferrer"
                  className={s.messageHeaderLinkUrl}
                >
                  {taskId}
                </a>
                {index < taskIds.length - 1 && ', '}
              </React.Fragment>
            ))}
          </div>
        )}
        {chatMessageId && isTaskChat && (
          <div>
            <a
              href={
                chatTopicId
                  ? `/chat-groups/${chatGroupId}/chats/${chatId}/topics/${chatTopicId}`
                  : `/chat-groups/${chatGroupId}/chats/${chatId}`
              }
              target="_blank"
              rel="noreferrer"
              className={s.messageHeaderLinkUrl}
            >
              Сообщение в чате
            </a>
          </div>
        )}
        {messageUserFullName && <span>{messageUserFullName(message)}&nbsp;</span>}
        <span className={s.messagesItemTime}>{formatDate(createdAt, 'dd MMM yyyy в HH:mm')}</span>
      </div>
      <div
        className={cn(s.messagesItemContent, {
          [s.withStatusChip]: hasStatusChip,
          [s.messagesItemContentGuest]: direction,
          [s.messagesItemContentMe]: !direction,
          [s.messagesItemContentChangeDepartment]: isChangeDepartment && !direction,
          [s.messagesItemContentDelay]: isDelay && !direction,
          [s.messagesItemContentForSpectator]: isSpectator,
          [s.messagesItemContentInner]: isInner
        })}
        onContextMenu={event => {
          if (isMessageChat && onContextMenu) {
            onContextMenu(event, message as IChatMessage);
          }
        }}
      >
        {chatMessageReply?.replyChatMessageId && (
          <div
            className={cn(s.quoteContainerBase, {
              [s.quoteContainer]: direction,
              [s.quoteContainerMe]: !direction
            })}
            role="button"
            tabIndex={0}
            onClick={() =>
              onScrollToMessage && onScrollToMessage(chatMessageReply.replyChatMessageId)
            }
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') {
                onScrollToMessage && onScrollToMessage(chatMessageReply.replyChatMessageId);
              }
            }}
          >
            {messageUserFullName && chatMessageReply?.replyMessage && (
              <div className={s.quoteHeader}>
                <span className={s.quoteUserName}>
                  <b>{messageUserFullName(chatMessageReply.replyMessage)}</b>
                </span>
                <FormatQuoteIcon className={s.quoteIcon} />
              </div>
            )}
            {chatMessageReply.quote ? (
              <HtmlContent html={chatMessageReply.quote} />
            ) : chatMessageReply.replyMessage && chatMessageReply.replyMessage.content ? (
              <HtmlContent html={chatMessageReply.replyMessage.content} />
            ) : chatMessageReply.replyMessage?.isHasFiles ? (
              <span>Файлы</span>
            ) : null}
          </div>
        )}

        <HtmlContent html={processedContent} />

        {/* ATTACHMENT */}

        {((message.files && message.files.length > 0) ||
          (isSending && message.attachments && message.attachments.length > 0)) && (
          <>
            <Divider sx={{borderColor: '#CCD6DF', mb: 3}} />
            <Stack
              spacing={8}
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              {message.files && message.files.length > 0 && (
                <Stack direction="row" spacing={6} useFlexGap flexWrap="wrap">
                  {message.files.map((file: IFile) => (
                    <ChatFile key={file.id} file={file} onGetFile={onGetFile} />
                  ))}
                </Stack>
              )}
              {isSending && message.attachments && message.attachments.length > 0 && (
                <Stack direction="row" spacing={6} useFlexGap flexWrap="wrap">
                  {message.attachments.map((file: any, index: number) => (
                    <ChatFile isSending={isSending} key={index} file={file} onGetFile={onGetFile} />
                  ))}
                </Stack>
              )}
            </Stack>
            <Stack direction="column">
              {!isSending && message.files && message.files.length > 0 && (
                <ChatFilesDownloadButton
                  className={!direction ? s.downloadItemSent : undefined}
                  messageId={id}
                  taskId={taskId}
                  subtaskId={subtaskId}
                  supportTaskId={supportTaskId}
                  chatId={chatId}
                />
              )}
            </Stack>
          </>
        )}
      </div>
      <div
        className={cn(s.messagesItemRead, {
          [s.messagesItemReadSent]: direction
        })}
      >
        {isSending && <CircularProgress color="inherit" size={16} />}

        {!isSending && (
          <span className={s.statusText}>
            {isTelegramChat && userId && (
              <Chip
                label={telegramId ? 'доставлено' : 'не доставлено'}
                color={telegramId ? 'success' : 'error'}
                size="small"
              />
            )}
            {isEdited && !isDeleted && <Chip label="изменено" color="info" size="small" />}
            {isDeleted && <Chip label="удалено" color="error" size="small" />}
          </span>
        )}

        {!isSending && (
          <Tooltip title={isRead ? 'Прочитано' : 'Не прочитано'}>
            <span className={s.statusRead}>{isRead ? <DoneAllIcon /> : <DoneIcon />}</span>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default ChatMessage;
