import {useCallback, useEffect} from 'react';

import {useLazyGetAllDepartmentsQuery} from '@/stores/api/dictionaries';
import {prepareDepartment} from '@/shared/utils/departmentUtils';
import {EFilterName, IOption} from '@/shared/models/tasksFilterModel';
import {usePrepareDict} from '@/shared/hooks/usePrepareDict';

type TUpdate = (key: EFilterName, options: IOption[]) => void;

export const useDepartmentFilters = (onUpdate: TUpdate) => {
  const {unitsIds} = usePrepareDict();

  const [trigger, {data: departments}] = useLazyGetAllDepartmentsQuery();

  const handleLoadDepartments = useCallback(() => {
    if (!departments && Object.keys(unitsIds).length) {
      trigger({});
    }
  }, [departments, trigger, unitsIds]);

  useEffect(() => {
    if (departments && Object.keys(unitsIds).length) {
      const departmentsWithUnits = departments.map(val => prepareDepartment(val, unitsIds));
      onUpdate(EFilterName.Departments, departmentsWithUnits);
    }
  }, [departments, onUpdate, unitsIds]);

  return {handleLoadDepartments};
};
