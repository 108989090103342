import React, {FC, SyntheticEvent, useEffect, useRef, useState} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import cn from 'classnames';
import ReactQuill from 'react-quill';

import {InputFile} from '@/components/UIKit/InputFile';
import {useOutsideClick} from '@/shared/hooks/useOutsideClick';
import {IChatCustomer} from '@/shared/models/chatModel.ts';

import {ReactComponent as SendMessageIcon} from './assets/send.svg';
import 'react-quill/dist/quill.snow.css';
import s from './ChatTextField.module.css';

interface IProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  isDisabledSend: boolean;
  isDisabledSendAll?: boolean;
  isDisabledInput?: boolean;
  isDisabledInputFile?: boolean;
  isSendAll?: boolean;
  hasAllButton?: boolean;
  onChangeFileInput: (files: FileList) => void;
  onSendClick: (message: string) => void;
  onSendAll: (message: string) => void;
  onChangeInput: (message: string) => void;
  onFocusInput: (message: string) => void;
  confirmContent?: () => React.ReactNode;
  chatCustomers?: IChatCustomer[];
}

const ChatTextField: FC<IProps> = ({
  isLoading,
  isDisabled,
  isDisabledSend,
  isDisabledSendAll,
  isDisabledInput,
  isDisabledInputFile,
  isSendAll,
  hasAllButton,
  onChangeFileInput,
  onSendClick,
  onSendAll,
  onChangeInput,
  onFocusInput,
  confirmContent,
  chatCustomers
}) => {
  const [message, setMessage] = useState('');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [showToolbar, setShowToolbar] = useState(false);
  const [filteredMentions, setFilteredMentions] = useState<IChatCustomer[]>([]);
  const [selectedMentionIndex, setSelectedMentionIndex] = useState(0);

  const editorRef = useRef<HTMLFormElement | null>(null);
  const quillRef = useRef<ReactQuill>(null);

  const handleClickOutside = () => {
    setShowToolbar(false);
  };

  useOutsideClick(editorRef, handleClickOutside);

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSendClick(message);
    setMessage('');
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleSubmitConfirm = () => {
    onSendAll(message);
    setMessage('');
    handleCloseConfirm();
  };

  const handleMentionSelect = (customer: IChatCustomer) => {
    const email = customer.customer.email;
    const quill = quillRef.current?.getEditor();
    if (quill) {
      let selection = quill.getSelection();
      if (!selection) {
        quill.focus();
        selection = quill.getSelection();
      }
      const cursorIndex = selection ? selection.index : quill.getLength() - 1;
      const textBeforeCursor = quill.getText(0, cursorIndex);
      const atIndex = textBeforeCursor.lastIndexOf('@');
      if (atIndex !== -1) {
        quill.deleteText(atIndex, cursorIndex - atIndex);
        quill.insertText(atIndex, email);
        quill.setSelection(atIndex + email.length, 0);
        quill.focus();
        setMessage(quill.root.innerHTML);
        onChangeInput(quill.root.innerHTML);
      }
    }
    setFilteredMentions([]);
  };

  const handleEditorChange = (content: string, _delta: any, _source: any, editor: any) => {
    setMessage(content);
    onChangeInput(content);
    const plainText = editor.getText();
    const selection = editor.getSelection();
    const cursorIndex = selection ? selection.index : plainText.length;
    const textBeforeCursor = editor.getText(0, cursorIndex);
    const atIndex = textBeforeCursor.lastIndexOf('@');
    if (atIndex !== -1 && (atIndex === 0 || textBeforeCursor[atIndex - 1] === ' ')) {
      const query = textBeforeCursor.slice(atIndex + 1, cursorIndex);
      if (chatCustomers && chatCustomers.length > 0) {
        const filtered = chatCustomers.filter(customer => {
          if (customer.customer.email !== 'Данные скрыты') {
            const fullInfo = [
              customer.customer.firstName,
              customer.customer.middleName,
              customer.customer.secondName,
              customer.customer.email
            ]
              .filter(Boolean)
              .join(' ');
            return fullInfo.toLowerCase().includes(query.toLowerCase());
          }
          return false;
        });
        setFilteredMentions(filtered);
      }
    } else {
      setFilteredMentions([]);
    }
  };

  // Сброс выбранного индекса при изменении списка
  useEffect(() => {
    if (filteredMentions.length > 0) {
      setSelectedMentionIndex(0);
    }
  }, [filteredMentions]);

  // Обработчик клавиш в редакторе для навигации по списку
  const handleEditorKeyDown = (e: React.KeyboardEvent) => {
    if (filteredMentions.length > 0) {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setSelectedMentionIndex(prevIndex => (prevIndex + 1) % filteredMentions.length);
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        setSelectedMentionIndex(prevIndex =>
          prevIndex - 1 < 0 ? filteredMentions.length - 1 : prevIndex - 1
        );
      } else if (e.key === 'Enter') {
        e.preventDefault();
        // Выбираем текущий элемент списка
        handleMentionSelect(filteredMentions[selectedMentionIndex]);
      }
    }
  };

  useEffect(() => {
    const toolbars = Array.from(document.querySelectorAll<HTMLElement>('div.ql-toolbar.ql-snow'));
    if (!toolbars.length) return;
    toolbars[0].style.display = showToolbar && !isDisabled ? 'block' : 'none';
  }, [isDisabled, showToolbar]);

  const modules = {
    toolbar: [
      [{header: [1, 2, 3, false]}],
      ['bold', 'italic', 'underline'],
      [{list: 'ordered'}, {list: 'bullet'}],
      ['link'],
      ['clean']
    ]
  };

  return (
    <>
      <form onSubmit={handleSubmit} ref={editorRef} className={s.form}>
        {showToolbar && filteredMentions.length > 0 && (
          <div className={s.mentionList}>
            {filteredMentions.map((customer, index) => (
              <div
                key={index}
                className={cn(s.mentionItem, {
                  [s.mentionItemSelected]: index === selectedMentionIndex
                })}
                onClick={() => handleMentionSelect(customer)}
                role="button"
                tabIndex={0}
                onMouseEnter={() => setSelectedMentionIndex(index)}
                onKeyDown={e => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleMentionSelect(customer);
                  }
                }}
              >
                <Stack direction="row">
                  <Typography variant="body1">
                    {customer.customer.firstName} {customer.customer.middleName}{' '}
                    {customer.customer.secondName}
                  </Typography>
                  <Typography variant="body1" sx={{color: 'var(--mute-color)'}} paddingLeft={6}>
                    {customer.customer.email}
                  </Typography>
                </Stack>
              </div>
            ))}
          </div>
        )}
        <Grid container direction="row" alignItems="end" className={s.grid}>
          <InputFile
            multiple
            onChange={onChangeFileInput}
            className={s.file}
            isDisabled={isDisabled || isDisabledInputFile}
          />
          <div className={s.editorWrapper}>
            <ReactQuill
              ref={quillRef}
              readOnly={isDisabled || isDisabledInput}
              theme="snow"
              value={message}
              placeholder="Введите сообщение"
              onChange={handleEditorChange}
              onKeyDown={handleEditorKeyDown}
              className={cn(s.editor)}
              modules={modules}
              onFocus={(_range, _source, editor) => {
                setShowToolbar(!isDisabledInput);
                onFocusInput(editor.getText());
              }}
            />
          </div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={cn(s.button, {
              [s.disabled]: isDisabled,
              [s.sendButton]: !hasAllButton
            })}
            disabled={isDisabled || isLoading || isDisabledSend}
          >
            {hasAllButton ? (
              'Ответить'
            ) : !isLoading ? (
              <SendMessageIcon />
            ) : (
              <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress size={32} color="inherit" />
              </Box>
            )}
          </Button>
          {hasAllButton && (
            <Button
              type="button"
              onClick={handleOpenConfirm}
              variant="contained"
              color="primary"
              className={cn(s.button, {[s.disabled]: isDisabled})}
              disabled={isDisabled || isLoading || isDisabledSendAll || !isSendAll}
            >
              Ответить всем
            </Button>
          )}
        </Grid>
      </form>
      <Dialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        aria-labelledby="Подтвердите отправку сообщения"
      >
        <DialogTitle>Отправить всем</DialogTitle>
        <DialogContent>
          {confirmContent ? (
            confirmContent()
          ) : (
            <DialogContentText>
              Письмо получат все сотрудники из раздела Кому и Копия
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirm}>Отменить</Button>
          <Button variant="contained" color="primary" onClick={handleSubmitConfirm}>
            Отправить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChatTextField;
